import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth/reducers';
import { me } from './me/reducers';
import groups from './groups/reducers';
import users from './users/reducers';
import contacts from './contacts/reducers';
import { settings } from './settings/reducers';
import { calendarSettings, calendar } from './calendar/reducers';
import { phone, phoneSettings } from './phone/reducers';
import { phoneRules } from './phoneRules/reducers';
import { cdr } from './cdr/reducers';
import files from './files/reducers';
import documents from './documents/reducers';
import wiki from './wiki/reducers';
import payments from './payments/reducers';
import confirm from './confirm/reducers';
import sticky from './sticky/reducers';
import grants from './grants/reducers';
import hotel from './hotel/reducers';
import notifications from './notifications/reducers';
import ext from './ext/reducers';
import mailoffice from './mailoffice/reducers';
import videocalls from './videocalls/reducers';
import fax from './fax/reducers';
import vbox from './vbox/reducers';
import chat from './chat/reducers';
import logo from './logo/reducers';
import presences from './presence/reducersPresences';
import puncher from './presence/reducersPuncher';
import vacations from './presence/reducersVacations';
import vocalassistant from './vocalassistant/reducers';
import registry from './presence/reducersRegistry';
import exportTemplates from './presence/reducersExportTemplate';
import { media } from './media/reducers';
import tickets from './tickets/reducers';
import ui from './ui/reducers';
import filebox from './filebox/reducers';
import usersProfiles from './usersProfiles/reducers';
import workingPlans from './workingPlans/reducers';
import sms from './sms/reducers';
import associations from './associations/reducers';
import externalUrl from './externalUrl/reducers';
import virtualOperator from './virtualOperator/reducers';

export default (history) =>
  combineReducers({
    associations,
    auth,
    calendar,
    calendarSettings,
    cdr,
    chat,
    confirm,
    contacts,
    documents,
    exportTemplates,
    ext,
    externalUrl,
    fax,
    filebox,
    files,
    grants,
    groups,
    hotel,
    logo,
    mailoffice,
    me,
    media,
    notifications,
    payments,
    phone,
    phoneRules,
    phoneSettings,
    presences,
    puncher,
    registry,
    router: connectRouter(history),
    settings,
    sms,
    sticky,
    tickets,
    ui,
    users,
    usersProfiles,
    vacations,
    vbox,
    videocalls,
    virtualOperator,
    vocalassistant,
    wiki,
    workingPlans,
  });
