import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { getCalendarFestivitiesSettings } from '../../../../js/calendar/selectors';
import Utils from '../../../../js/lib/utils';
import FormSectionTitle from '../../../../components/FormSectionTitle';
import Button from '../../../../components/formControls/Button';
import FestivitiesModalEdit from './PbxVirtualOperatorFestivitiesModalEdit';
import {
  getEnterpriseFestivities,
  getEnterpriseFestivitiesMessage,
  getVirtualOperatorMessages,
} from '../../../../js/virtualOperator/selectors';
import { hasGrants } from '../../../../js/me/selectors';
import { get } from 'jquery';

const messages = defineMessages({
  CAPODANNO: {
    id: 'PbxVirtualOperatorCustomerFestivities.CAPODANNO.label',
    defaultMessage: 'First of the year',
  },
  EPIFANIA: {
    id: 'PbxVirtualOperatorCustomerFestivities.EPIFANIA.label',
    defaultMessage: 'Epiphany',
  },
  LIBERAZIONE: {
    id: 'PbxVirtualOperatorCustomerFestivities.LIBERAZIONE.label',
    defaultMessage: 'Freedom day',
  },
  LAVORATORI: {
    id: 'PbxVirtualOperatorCustomerFestivities.LAVORATORI.label',
    defaultMessage: 'Workers day',
  },
  REPUBBLICA: {
    id: 'PbxVirtualOperatorCustomerFestivities.REPUBBLICA.label',
    defaultMessage: 'Republic day',
  },
  FERRAGOSTO: {
    id: 'PbxVirtualOperatorCustomerFestivities.FERRAGOSTO.label',
    defaultMessage: 'Mid-August',
  },
  OGNISSANTI: {
    id: 'PbxVirtualOperatorCustomerFestivities.OGNISSANTI.label',
    defaultMessage: 'All Saints',
  },
  IMMACOLATA: {
    id: 'PbxVirtualOperatorCustomerFestivities.IMMACOLATA.label',
    defaultMessage: 'Immaculate Conception',
  },
  NATALE: {
    id: 'PbxVirtualOperatorCustomerFestivities.NATALE.label',
    defaultMessage: 'Christmas',
  },
  SANTO_STEFANO: {
    id: 'PbxVirtualOperatorCustomerFestivities.SANTO_STEFANO.label',
    defaultMessage: 'Day after Christmas',
  },
  PASQUA: {
    id: 'PbxVirtualOperatorCustomerFestivities.PASQUA.label',
    defaultMessage: 'Easter',
  },
  PASQUETTA: {
    id: 'PbxVirtualOperatorCustomerFestivities.PASQUETTA.label',
    defaultMessage: 'Day after Easter',
  },
  allSelected: {
    id: 'PbxVirtualOperatorCustomerFestivities.allSelected',
    defaultMessage: 'All selected',
  },
  festivities: {
    id: 'PbxVirtualOperatorCustomerFestivities.festivities.label',
    defaultMessage: 'Festivities',
  },
  standardFestivities: {
    id: 'PbxVirtualOperatorCustomerFestivities.standardFestivities.label',
    defaultMessage: 'National Festivities',
  },
  customFestivities: {
    id: 'PbxVirtualOperatorCustomerFestivities.customFestivities.label',
    defaultMessage: 'Added Festivities',
  },
  message: {
    id: 'PbxVirtualOperatorCustomerFestivities.message.label',
    defaultMessage: 'Message',
  },
  noFestivities: {
    id: 'PbxVirtualOperatorCustomerFestivities.noFestivities.label',
    defaultMessage: 'No festivities',
  },
  buttonEdit: {
    id: 'PbxVirtualOperatorCustomerOpening.buttonEdit',
    defaultMessage: 'Edit',
  },
});

class PbxVirtualOperatorCustomerFestivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFestivitiesOpen: false,
    };
  }

  toggleFestivitiesModal = () => {
    const { modalFestivitiesOpen } = this.state;
    this.setState({
      modalFestivitiesOpen: !modalFestivitiesOpen,
    });
  };
  render() {
    const {
      enterpriseFestivities,
      customerFestivities,
      createdMessages,
      festivityMessage,
      canEdit,
      intl: { formatMessage },
    } = this.props;

    const selectedStandardFestivites = [];
    const selectedCustomFestivites = [];
    const standardFestivities = Utils.retrieveFestivities(
      [],
      [],
      new Date().getFullYear()
    );
    if (enterpriseFestivities) {
      if (
        !standardFestivities.some(
          (item) => !enterpriseFestivities.standard.includes(item.description)
        )
      ) {
        selectedStandardFestivites.push(formatMessage(messages.allSelected));
      } else {
        enterpriseFestivities.standard.forEach((festivity) => {
          selectedStandardFestivites.push(
            formatMessage(
              messages[
                standardFestivities.find((f) => f.description === festivity)
                  .description
              ]
            )
          );
        });
      }
      enterpriseFestivities.custom.forEach((festivity) => {
        if (
          customerFestivities.added &&
          customerFestivities.added.find((f) => f.description === festivity)
        ) {
          selectedCustomFestivites.push(
            customerFestivities.added.find((f) => f.description === festivity)
              .description
          );
        }
      });
    }
    return (
      <>
        <div>
          <FormSectionTitle className="mt-3 mb-3">
            {formatMessage(messages.festivities)}
          </FormSectionTitle>
          {(selectedStandardFestivites.length > 0 ||
          selectedCustomFestivites.length > 0) ? (
            <>
              {selectedStandardFestivites.length > 0 && (
                <div>
                  <span className="font-weight-bold">
                    {formatMessage(messages.standardFestivities)}:&nbsp;
                  </span>
                  <span>{selectedStandardFestivites.join(', ')}</span>
                </div>
              )}
              {selectedCustomFestivites.length > 0 && (
                <div>
                  <span className="font-weight-bold">
                    {formatMessage(messages.customFestivities)}:&nbsp;
                  </span>
                  <span>{selectedCustomFestivites.join(', ')}</span>
                </div>
              )}
              {festivityMessage && (
                <div>
                  <span className="font-weight-bold">
                    {formatMessage(messages.message)}:&nbsp;
                  </span>
                  <span>
                    {
                      createdMessages.find(
                        (item) => item.id === festivityMessage
                      )?.name
                    }
                  </span>
                </div>
              )}
            </>
          ) : (
            <span>{formatMessage(messages.noFestivities)}</span>
          )}
          {canEdit && (
            <div className="pt-3 text-center">
              <Button
                className="btn btn-primary"
                text={formatMessage(messages.buttonEdit)}
                onClick={this.toggleFestivitiesModal}
              />
            </div>
          )}
        </div>
        {this.state.modalFestivitiesOpen && (
          <FestivitiesModalEdit
            isOpen={this.state.modalFestivitiesOpen}
            onClose={this.toggleFestivitiesModal}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerFestivities: getCalendarFestivitiesSettings(state),
    enterpriseFestivities: getEnterpriseFestivities(state),
    festivityMessage: getEnterpriseFestivitiesMessage(state),
    canEdit: hasGrants(state, 'PBX_HOLIDAYS_WRITE'),
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(
  connect(mapStateToProps)(PbxVirtualOperatorCustomerFestivities)
);
