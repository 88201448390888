import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../../components/formControls/Button';
import {
  isSaveMessageError,
  isSaveMessageLoaded,
} from '../../../../js/virtualOperator/selectors';
import { saveVirtualOperatorMessageRequest } from '../../../../js/virtualOperator/actions';
import ToastMessage from '../../../../components/messages/ToastMessage';
import Label from '../../../../components/formControls/Label';
import Input from '../../../../components/formControls/Input';
import TextArea from '../../../../components/formControls/Textarea';

const messages = defineMessages({
  title: {
    id: 'PbxVirtualOperatorMessageModalEdit.title',
    defaultMessage: 'Edit',
  },
  name: {
    id: 'PbxVirtualOperatorMessageModalEdit.name',
    defaultMessage: 'Name',
  },
  text: {
    id: 'PbxVirtualOperatorMessageModalEdit.text',
    defaultMessage: 'Text',
  },
  save: {
    id: 'PbxVirtualOperatorMessageModalEdit.button.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'PbxVirtualOperatorMessageModalEdit.button.cancel',
    defaultMessage: 'Cancel',
  },
  existingNameError: {
    id: 'PbxVirtualOperatorMessageModalEdit.existingNameError',
    defaultMessage: 'There is already a message with that name',
  },
  errorSaving: {
    id: 'PbxVirtualOperatorMessageModalEdit.errorSaving',
    defaultMessage: 'Error saving',
  },
  missingField: {
    id: 'PbxVirtualOperatorMessageModalEdit.missingField',
    defaultMessage: 'Fill this field',
  },
});

class PbxVirtualOperatorMessageModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.message.name,
      text: props.message.text,
      errors: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.saveLoaded &&
      !prevProps.saveLoaded &&
      !this.props.saveError
    ) {
      this.props.onClose();
    }
  };

  handleName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleText = (e) => {
    this.setState({
      text: e.target.value,
    });
  };

  save = () => {
    console.log(this.props.message);
    const errors = {};
    if (!this.state.name) {
      errors.name = this.props.intl.formatMessage(messages.missingField);
    }
    if (!this.state.text) {
      errors.text = this.props.intl.formatMessage(messages.missingField);
    }
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return;
    }
    this.props.saveMessage({
      id: this.props.message.id,
      data: { name: this.state.name, text: this.state.text },
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      saveLoaded,
      saveError,
      intl: { formatMessage },
    } = this.props;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={onClose}>
          {formatMessage(messages.title)}
        </ModalHeader>

        <ModalBody>
          {saveError && (
            <ToastMessage
              type="danger"
              text={
                saveError === 'EXISTING_NAME'
                  ? formatMessage(messages.existingNameError)
                  : formatMessage(messages.errorSaving)
              }
              closeTimeout={5000}
            />
          )}
          <div className="row">
            <div className="col-xl-12 p-3">
              <Label for="name" mandatory>
                {formatMessage(messages.name)}
              </Label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={this.handleName}
                value={this.state.name}
                error={this.state.errors.name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 p-3">
              <Label for="text" mandatory>
                {formatMessage(messages.text)}
              </Label>
              <TextArea
                id="text"
                name="text"
                type="text"
                onChange={this.handleText}
                value={this.state.text}
                error={this.state.errors.text}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.save}
            text={formatMessage(messages.save)}
            loading={!saveLoaded}
          />
          <button
            type="button"
            className="btn btn-outline-primary ml-2"
            onClick={onClose}
          >
            {formatMessage(messages.cancel)}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

PbxVirtualOperatorMessageModalEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    saveLoaded: isSaveMessageLoaded(state),
    saveError: isSaveMessageError(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    saveMessage: saveVirtualOperatorMessageRequest,
  })(PbxVirtualOperatorMessageModalEdit)
);
