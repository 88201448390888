import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../../components/formControls/Button';
import { saveOpeningRequest } from '../../../../js/virtualOperator/actions';
import ToastMessage from '../../../../components/messages/ToastMessage';
import {
  getEnterpriseOpeningMessage,
  getVirtualOperatorMessages,
  isSaveOpeningError,
  isSaveOpeningLoaded,
} from '../../../../js/virtualOperator/selectors';
import OpeningComponent from './PbxVirtualOperatorOpeningComponent';

const messages = defineMessages({
  title: {
    id: 'OpeningModalEdit.title',
    defaultMessage: 'Edit Opening Hours',
  },
  enabled: {
    id: 'OpeningModalEdit.enabled',
    defaultMessage: 'Enabled',
  },
  day: {
    id: 'OpeningModalEdit.day',
    defaultMessage: 'Day',
  },
  entrance: {
    id: 'OpeningModalEdit.entrance',
    defaultMessage: 'Entrance',
  },
  exit: {
    id: 'OpeningModalEdit.exit',
    defaultMessage: 'Exit',
  },
  serverError: {
    id: 'OpeningModalEdit.serverError',
    defaultMessage: 'Sorry, there has been a server error.',
  },
  save: {
    id: 'OpeningModalEdit.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'OpeningModalEdit.cancel',
    defaultMessage: 'Cancel',
  },
  missingWeekHours: {
    id: 'OpeningModalEdit.missingWeekHours',
    defaultMessage: 'Fill the time',
  },
  missingField: {
    id: 'OpeningModalEdit.missingField',
    defaultMessage: 'Fill this field',
  },
  wrongFormat: {
    id: 'OpeningModalEdit.wrongFormat',
    defaultMessage: 'Wrong format.',
  },
});

class OpeningModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: props.openingTable || {},
      message: {
        value: props.openingMessage,
        label: props.openingMessage
          ? props.createdMessages.find(
              (message) => message.id === props.openingMessage
            ).name
          : '',
      },
      errors: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      !this.props.saveLoading &&
      prevProps.saveLoading &&
      !this.props.saveError
    ) {
      this.props.onClose();
    }
  };

  handleChangeDay = (day, data) => {
    let { days } = this.state;
    if (!data) {
      days[day] = null;
    } else {
      days = {
        ...days,
        [day]: this.state.days[day]
          ? { ...this.state.days[day], ...data }
          : data,
      };
    }
    this.setState({ days, errors: {} }, () => {
      if (
        !Object.keys(this.state.days).some(
          (key) => this.state.days[key] && this.state.days[key].enabled
        )
      ) {
        this.setState({ message: { value: null, label: '' } });
      }
    });
  };

  handleMessageSelection = (selected) => {
    this.setState({
      ...this.state,
      message: selected,
    });
  };

  save = () => {
    const errors = {};
    Object.keys(this.state.days).forEach((day) => {
      errors[day] = {};
      if (this.state.days[day] && this.state.days[day].enabled) {
        if (!this.state.days[day].entrance1) {
          errors[day].entrance1 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (!this.state.days[day].exit1) {
          errors[day].exit1 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (!this.state.days[day].entrance2 && this.state.days[day].exit2) {
          errors[day].entrance2 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (this.state.days[day].entrance2 && !this.state.days[day].exit2) {
          errors[day].exit2 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (
          this.state.days[day].entrance1 &&
          this.state.days[day].entrance1.indexOf('_') >= 0
        ) {
          errors[day].entrance1 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (
          this.state.days[day].exit1 &&
          this.state.days[day].exit1.indexOf('_') >= 0
        ) {
          errors[day].exit1 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (
          this.state.days[day].entrance2 &&
          this.state.days[day].entrance2.indexOf('_') >= 0
        ) {
          errors[day].entrance2 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (
          this.state.days[day].exit2 &&
          this.state.days[day].exit2.indexOf('_') >= 0
        ) {
          errors[day].exit2 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (this.state.days[day].entrance1 && this.state.days[day].exit1) {
          if (
            moment(this.state.days[day].entrance1, 'HH:mm').isSameOrAfter(
              moment(this.state.days[day].exit1, 'HH:mm')
            )
          ) {
            errors[day].entrance1 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
            errors[day].exit1 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
          }
        }
        if (this.state.days[day].entrance2 && this.state.days[day].exit2) {
          if (
            moment(this.state.days[day].entrance2, 'HH:mm').isSameOrAfter(
              moment(this.state.days[day].exit2, 'HH:mm')
            )
          ) {
            errors[day].entrance2 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
            errors[day].exit2 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
          }
        }
        if (
          this.state.days[day].entrance1 &&
          this.state.days[day].exit1 &&
          this.state.days[day].entrance2 &&
          this.state.days[day].exit2
        ) {
          if (
            moment(this.state.days[day].exit1, 'HH:mm').isSameOrAfter(
              moment(this.state.days[day].entrance2, 'HH:mm')
            )
          ) {
            errors[day].entrance2 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
          }
        }
      }
      if (Object.keys(errors[day]).length === 0) {
        delete errors[day];
      }
      if (this.state.days[day] && !this.state.days[day].enabled) {
        delete this.state.days[day];
      }
    });
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return;
    }
    this.props.saveOpening({
      opening: this.state.days,
      message: this.state.message ? this.state.message.value : null,
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      saveError,
      saveLoading,
      intl: { formatMessage },
    } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={onClose}>
          {formatMessage(messages.title)}
        </ModalHeader>
        <ModalBody>
          {saveError && (
            <ToastMessage
              type="danger"
              text={
                messages[saveError]
                  ? formatMessage(messages[saveError])
                  : formatMessage(messages.serverError)
              }
              messageCode={saveError.status || 400}
            />
          )}
          <OpeningComponent
            opening={this.state.days}
            chosenMessage={this.state.message}
            errors={this.state.errors}
            handleChangeDay={this.handleChangeDay}
            handleMessageSelection={this.handleMessageSelection}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.save}
            text={formatMessage(messages.save)}
            loading={saveLoading}
          />
          <button
            type="button"
            className="btn btn-outline-primary ml-2"
            onClick={onClose}
          >
            {formatMessage(messages.cancel)}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

OpeningModalEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    saveLoading: !isSaveOpeningLoaded(state),
    saveError: isSaveOpeningError(state),
    openingMessage: getEnterpriseOpeningMessage(state),
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    saveOpening: saveOpeningRequest,
  })(OpeningModalEdit)
);
