import sortBy from 'lodash.sortby';
import values from 'lodash.values';
import { createSelector } from 'reselect';
import { PhoneEnums } from '../phone/PhoneUtils';
import { addUsernameToCallid } from '../phone/PbxSettingsUtils';
import {
  getMeMainExtensionUsername,
  getMeMainExtensionNumber,
  getMeWebrtcExtensionNumber,
  getAllCallsById,
} from '../phone/selectors';

const defaultList = [];
const defaultObject = {};

export const getMeId = (state) => state.me.id;
export const getMeType = (state) => state.me.type;
export const getMeGrants = (state) => state.me.grants;
export const hasGrants = (state, grant) => state.me.grants.indexOf(grant) > -1;
export const addressbookGrant = () => true; //state.me.grants.indexOf('ADDRESSBOOK_USAGE') > -1;
export const getMeAlias = (state) => state.me.fullname;
export const getMeRelease = (state) => state.me.release;
export const getMeCalls = (state) => state.me.calls;
export const getMeAllCalls = (state) => {
  let calls = [];
  Object.keys(state.me.calls).forEach((number) => {
    const call = Object.keys(state.me.calls[number]).map(
      (callId) =>
        // callback for abilis
        (state.me.calls[number][callId].callback && {
          called: number,
          callid: '0',
          calling: number,
          conference: false,
          direction: PhoneEnums.Direction.In,
          status: PhoneEnums.CallsStatuses.callback,
          timestamp: null,
          username: getMeMainExtensionUsername(state),
        }) ||
        state.phone.allCallsById[
          addUsernameToCallid(callId, getMeMainExtensionUsername(state))
        ] ||
        null
    );
    calls = calls.concat(call);
  });
  return calls.filter((call) => call);
};
export const getMeConferenceCallId = (state) =>
  state.me.conference.callId || null;
export const getMeCallsAndConferences = createSelector(
  [
    getMeCalls,
    getAllCallsById,
    getMeMainExtensionUsername,
    getMeConferenceCallId,
  ],
  (meCalls, allCallsById, meMainExtensionUsername, meConferenceCallId) => {
    let calls = [];
    Object.keys(meCalls).forEach((number) => {
      const call = Object.keys(meCalls[number]).map(
        (callId) =>
          allCallsById[addUsernameToCallid(callId, meMainExtensionUsername)] ||
          null
      );
      calls = calls.concat(call);
    });
    if (meConferenceCallId) {
      calls.push(
        allCallsById[
          addUsernameToCallid(meConferenceCallId, meMainExtensionUsername)
        ] || null
      );
    }
    return calls.filter((call) => call);
  }
);
export const getMePhysicalCalls = createSelector(
  [
    getMeCalls,
    getAllCallsById,
    getMeMainExtensionNumber,
    getMeMainExtensionUsername,
  ],
  (meCalls, allCallsById, meMainExtensionNumber, meMainExtensionUsername) => {
    let calls = [];
    Object.keys(meCalls).forEach((number) => {
      if (number === meMainExtensionNumber) {
        const call = Object.keys(meCalls[number]).map(
          (callId) =>
            allCallsById[
              addUsernameToCallid(callId, meMainExtensionUsername)
            ] || null
        );
        calls = calls.concat(call);
      }
    });
    return calls.filter((call) => call);
  }
);
export const getMeWebrtcCalls = createSelector(
  [
    getMeCalls,
    getAllCallsById,
    getMeWebrtcExtensionNumber,
    getMeMainExtensionUsername,
  ],
  (meCalls, allCallsById, meWebrtcExtensionNumber, meMainExtensionUsername) => {
    let calls = [];
    Object.keys(meCalls).forEach((number) => {
      if (number === meWebrtcExtensionNumber) {
        const call = Object.keys(meCalls[number]).map(
          (callId) =>
            allCallsById[
              addUsernameToCallid(callId, meMainExtensionUsername)
            ] || null
        );
        calls = calls.concat(call);
      }
    });
    return calls.filter((call) => call);
  }
);
export const getMeActiveCalls = createSelector([getMeCalls], (meCalls) => {
  let calls = [];
  Object.keys(meCalls).forEach((number) => {
    const call = Object.keys(meCalls[number]).map(
      (callId) => meCalls[number][callId]
    );
    calls = calls.concat(call);
  });
  return calls.filter((call) => call && call.active);
});
export const getMeIncomingCalls = (state) => {
  const allCalls = getMeAllCalls(state);
  return allCalls.filter(
    (call) => call.status === PhoneEnums.CallsStatuses.alerting
  );
};

export const getMeActiveCallId = (state) => {
  const number = Object.keys(state.me.calls)[0];
  if (number) {
    return Object.keys(state.me.calls[number])[0];
  }
  return null;
};
export const getMeConferenceId = (state) => state.me.conference.id || null;
export const getConferenceUsersIds = (state) =>
  state.me.conference.users && Object.keys(state.me.conference.users);
export const getConferenceUsers = (state) =>
  (state.me.conference.users &&
    sortBy(
      Object.keys(state.me.conference.users).map(
        (id) => state.me.conference.users[id]
      ),
      'owner'
    )) ||
  defaultList;
export const isMeInConference = (state) =>
  state.me.conference.users &&
  state.me.conference.users[getMeMainExtensionUsername(state)] &&
  state.me.conference.users[getMeMainExtensionUsername(state)].in;
export const isMeParked = createSelector(
  [getMeCalls, getAllCallsById, getMeMainExtensionUsername],
  (meCalls, allCallsById, meMainExtensionUsername) => {
    const activeCalls = [];
    Object.keys(meCalls).forEach((number) => {
      Object.keys(meCalls[number]).forEach((callId) => {
        if (meCalls[number][callId].active) {
          activeCalls.push(callId);
        }
      });
    });
    return activeCalls.some((activeCall) => {
      const call =
        allCallsById[addUsernameToCallid(activeCall, meMainExtensionUsername)];
      return call && call.status === PhoneEnums.UserStatuses.parked;
    });
  }
);
export const isActiveAssistedTransfer = (state) =>
  state.me.activeAssistedTransfer;
export const hasRecordedCurrentCall = (state) => state.me.hasRecorded;
export const getOrderedExtensions = (state) =>
  state.me.extensions.current || defaultList;
export const getWakeupEnabled = (state) => state.me.customer.wakeupEnabled;
export const getCustomerLogo = (state) =>
  state.me.customer.logo || defaultObject;
export const getCustomerLogoUrl = (state) => state.me.customer.logo;
export const getVideocallInvitationRoomId = (state) => state.me.videocallRoomId;
export const getPbxs = (state) => state.me.customerPbxs.pbxs;
export const getPbxsById = (state) => state.me.customerPbxs.byId;
export const getPbxById = (state) => state.me.customerPbxs.byId[state.me.idPbx];
export const getPbxSelectOptions = (state, emptyValue) => {
  const list = emptyValue
    ? [
        {
          value: '',
          label: '',
        },
      ]
    : [];
  const pbxs = getPbxs(state);
  if (pbxs) {
    return list.concat(pbxs.map((pbx) => ({ value: pbx.id, label: pbx.name })));
  }
  return list;
};
export const getLastUpdatePwd = (state) => state.me.lastUpdatePwd;
export const getMeEmail = (state) => state.me.email;
export const getAbilisVboxGreeting = (state) => state.me.greeting;
export const hasAdminPanelGrants = createSelector([getMeGrants], (meGrants) =>
  [
    'BACKEND_VIEW',
    'BACKEND_EDIT_SIMPLE',
    'BACKEND_EDIT_FULL',
    'BACKEND_SERVICES',
    'BACKEND_REPORT',
    'BACKEND_LOG',
    'BACKEND_APP',
    'BACKEND_BETA',
    'BACKEND_DESKTOP_VERSIONS',
    'BACKEND_CREDIT_GIFT',
    'BACKEND_WEBRTC_SERVERS',
  ].some((adminGrant) => meGrants.indexOf(adminGrant) >= 0)
);

export const getMeGrantsIds = (state) => {
  const grants = values(state.grants.grantsByCode);
  let grantIds = [];
  const meGrants = state.me.grants;
  grants.forEach((grant) => {
    meGrants.forEach((meGrant) => {
      if (grant.description === meGrant) {
        grantIds = [...grantIds, grant.id];
      }
    });
  });
  return grantIds;
};
export const isHelpVisible = (state) => state.me.showHelp;
export const isMePbxAdmin = (state) => state.me.pbxAdmin;
export const isMePunchingsValidator = (state) => state.me.punchingsValidator;
export const isMeVacationsValidator = (state) => state.me.vacationsValidator;
export const getCustomerRegistryFields = (state) =>
  state.me.customerPbxs.registryFields;
export const isMyPuncherEnabled = (state) => state.me.puncherEnabled;
export const getSetStatusLoading = (state) => state.me.setStatus.loaded;
export const getMeUserStatus = (state) => state.me.status;
export const getSetStatusSuccess = (state) => state.me.setStatus.success;
export const getSetStatusError = (state) => state.me.setStatus.error;
export const isTicketingEnabled = (state) => state.me.customer.ticketingEnabled;
export const isPublicChatAlert = (state) => state.me.customer.publicChatAlert;
export const isAvoidPasswordExpiration = (state) =>
  state.me.customer.avoidPasswordExpiration;
export const otherSessions = (state) => state.me.otherSessions;
export const getCustomerId = (state) => state.me.idCustomer;
export const isAmbrogioCustomer = (state) =>
  state.me.customerType === 'AMBROGIO';
export const isSMSenabled = (state) => state.me.customer.smsEnabled;
export const isSMSCampaignEnabled = (state) =>
  state.me.customer.smsCampaignEnabled;
export const isAppointmentEnabled = (state) =>
  state.me.customer.appointmentEnabled;
export const isVirtualOperatorEnabled = (state) =>
  state.me.customer.virtualOperatorEnabled;
