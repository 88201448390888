import React from 'react';
import ReactDOM from 'react-dom';
import jwt from 'jsonwebtoken';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import App from './App';
import history from './history';
import rootSagaAuth from './js/auth/sagas';
import rootSagaMedia from './js/media/sagas';
import rootSagaMe from './js/me/sagas';
import rootSagaUsers from './js/users/sagas';
import rootSagaGroups from './js/groups/sagas';
import rootSagaWiki from './js/wiki/sagas';
import rootSagaPhone from './js/phone/sagas';
import rootSagaPhoneRules from './js/phoneRules/sagas';
import rootSagaCdr from './js/cdr/sagas';
import rootSagaPayments from './js/payments/sagas';
import rootSagaAbook from './js/contacts/sagas';
import rootSagaStickyNotes from './js/sticky/sagas';
import rootSagaGrants from './js/grants/sagas';
import rootSagaHotel from './js/hotel/sagas';
import rootSagaWs from './js/websocket/sagas';
import rootSagaNotifications from './js/notifications/sagas';
import rootSagaCalendar from './js/calendar/sagas';
import rootSagaExt from './js/ext/sagas';
import rootSagaVideocalls from './js/videocalls/sagas';
import rootSagaFax from './js/fax/sagas';
import rootSagaVbox from './js/vbox/sagas';
import rootSagaSettings from './js/settings/sagas';
import rootSagaFiles from './js/files/sagas';
import { authSuccess, loginSuccess, logoutSuccess } from './js/auth/actions';
import rootSagaMailoffice from './js/mailoffice/sagas';
import rootSagaLogo from './js/logo/sagas';
import rootSagaChat from './js/chat/sagas';
import rootSagaDocuments from './js/documents/sagas';
import rootSagaVocalassistant from './js/vocalassistant/sagas';
import rootSagaPuncher from './js/presence/sagas';
import createRootReducer from './js/rootReducer';
import { YnSid } from './js/lib/YnCookies';
import rootSagaTickets from './js/tickets/sagas';
import rootSagaFilebox from './js/filebox/sagas';
import rootSagaUsersProfiles from './js/usersProfiles/sagas';
import rootSagaWorkingPlans from './js/workingPlans/sagas';
import rootSagaSMS from './js/sms/sagas';
import rootSagaAssociations from './js/associations/sagas';
import rootSagaExternalUrl from './js/externalUrl/sagas';
import rootSagaVirtualOperator from './js/virtualOperator/sagas';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  )
);
sagaMiddleware.run(rootSagaAuth);
sagaMiddleware.run(rootSagaMedia);
sagaMiddleware.run(rootSagaMe);
sagaMiddleware.run(rootSagaUsers);
sagaMiddleware.run(rootSagaGroups);
sagaMiddleware.run(rootSagaWiki);
sagaMiddleware.run(rootSagaPhone);
sagaMiddleware.run(rootSagaPhoneRules);
sagaMiddleware.run(rootSagaCdr);
sagaMiddleware.run(rootSagaPayments);
sagaMiddleware.run(rootSagaAbook);
sagaMiddleware.run(rootSagaStickyNotes);
sagaMiddleware.run(rootSagaGrants);
sagaMiddleware.run(rootSagaHotel);
sagaMiddleware.run(rootSagaWs);
sagaMiddleware.run(rootSagaNotifications);
sagaMiddleware.run(rootSagaCalendar);
sagaMiddleware.run(rootSagaExt);
sagaMiddleware.run(rootSagaMailoffice);
sagaMiddleware.run(rootSagaVideocalls);
sagaMiddleware.run(rootSagaFax);
sagaMiddleware.run(rootSagaVbox);
sagaMiddleware.run(rootSagaChat);
sagaMiddleware.run(rootSagaSettings);
sagaMiddleware.run(rootSagaFiles);
sagaMiddleware.run(rootSagaVocalassistant);
sagaMiddleware.run(rootSagaLogo);
sagaMiddleware.run(rootSagaPuncher);
sagaMiddleware.run(rootSagaDocuments);
sagaMiddleware.run(rootSagaTickets);
sagaMiddleware.run(rootSagaFilebox);
sagaMiddleware.run(rootSagaUsersProfiles);
sagaMiddleware.run(rootSagaWorkingPlans);
sagaMiddleware.run(rootSagaSMS);
sagaMiddleware.run(rootSagaAssociations);
sagaMiddleware.run(rootSagaExternalUrl);
sagaMiddleware.run(rootSagaVirtualOperator);

YnSid.refreshSid().then((ynSid) => {
  if (ynSid) {
    const cookie = jwt.decode(ynSid);
    if (cookie && cookie.idUser) {
      store.dispatch(authSuccess());
      const { idPbx, idUser, idCustomer, pbxSupplier } = cookie;
      store.dispatch(loginSuccess({ idPbx, idUser, idCustomer, pbxSupplier }));
    } else {
      store.dispatch(logoutSuccess({}));
    }
  } else if (window.navigator.onLine) store.dispatch(logoutSuccess({}));

  ReactDOM.render(
    <Router>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </Provider>
    </Router>,
    document.getElementById('yn')
  );
});
