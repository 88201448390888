import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../../components/formControls/Button';
import Select2 from '../../../../components/formControls/Select2';
import {
  isSaveUserError,
  isSaveUserLoaded,
} from '../../../../js/virtualOperator/selectors';
import { saveVirtualOperatorUserRequest } from '../../../../js/virtualOperator/actions';
import ToastMessage from '../../../../components/messages/ToastMessage';
import Label from '../../../../components/formControls/Label';
import Input from '../../../../components/formControls/Input';
import { VirtualOperatorEnums } from '../../../../js/virtualOperator/VirtualOperatorUtils';

const messages = defineMessages({
  title: {
    id: 'PbxVirtualOperatorUserModalEdit.title',
    defaultMessage: 'Edit {fullname}',
  },
  pronunciation: {
    id: 'PbxVirtualOperatorUserModalEdit.pronunciation',
    defaultMessage: 'Additional pronunciation',
  },
  transfer: {
    id: 'PbxVirtualOperatorUserModalEdit.transfer',
    defaultMessage: 'Transfer profile',
  },
  BLIND: {
    id: 'PbxVirtualOperatorUserModalEdit.BLIND',
    defaultMessage: 'Blind',
  },
  SUPERVISED: {
    id: 'PbxVirtualOperatorUserModalEdit.SUPERVISED',
    defaultMessage: 'Supervised',
  },
  CONSULTATION: {
    id: 'PbxVirtualOperatorUserModalEdit.CONSULTATION',
    defaultMessage: 'Consultation',
  },
  save: {
    id: 'PbxVirtualOperatorUserModalEdit.button.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'PbxVirtualOperatorUserModalEdit.button.cancel',
    defaultMessage: 'Cancel',
  },
  errorSaving: {
    id: 'PbxVirtualOperatorUserModalEdit.errorSaving',
    defaultMessage: 'Error saving',
  },
});

class PbxVirtualOperatorUserModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pronunciation: props.user.pronunciation,
      transfer: props.user.transfer
        ? {
            value: props.user.transfer,
            label: props.intl.formatMessage(messages[props.user.transfer]),
          }
        : { value: null, label: '' },
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.saveLoaded &&
      !prevProps.saveLoaded &&
      !this.props.saveError
    ) {
      this.props.onClose();
    }
  };

  handlePronunciation = (e) => {
    this.setState({
      pronunciation: e.target.value,
    });
  };

  handleTransfer = (selected) => {
    this.setState({
      transfer: selected,
    });
  };

  save = () => {
    this.props.saveUser({
      id: this.props.user.id,
      data: {
        pronunciation: this.state.pronunciation,
        transfer: this.state.transfer ? this.state.transfer.value : null,
      },
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      saveLoaded,
      saveError,
      intl: { formatMessage },
    } = this.props;
    const transferOptions = [
      {
        value: null,
        label: '',
      },
    ];
    Object.keys(VirtualOperatorEnums.TransferProfile).forEach((key) => {
      transferOptions.push({
        value: key,
        label: formatMessage(messages[key]),
      });
    });

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={onClose}>
          {formatMessage(messages.title, {
            fullname: this.props.user.fullname,
          })}
        </ModalHeader>

        <ModalBody>
          {saveError && (
            <ToastMessage
              type="danger"
              text={formatMessage(messages.errorSaving)}
              closeTimeout={5000}
            />
          )}
          <div className="row">
            <div className="col-xl-12 p-3">
              <Label for="pronunciation">
                {formatMessage(messages.pronunciation)}
              </Label>
              <Input
                id="pronunciation"
                name="pronunciation"
                type="text"
                onChange={this.handlePronunciation}
                value={this.state.pronunciation}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 p-3">
              <Label for="transfer">{formatMessage(messages.transfer)}</Label>
              <Select2
                id="transfer"
                name="transfer"
                options={transferOptions}
                isClearable
                onChange={this.handleTransfer}
                value={this.state.transfer}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.save}
            text={formatMessage(messages.save)}
            loading={!saveLoaded}
          />
          <button
            type="button"
            className="btn btn-outline-primary ml-2"
            onClick={onClose}
          >
            {formatMessage(messages.cancel)}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

PbxVirtualOperatorUserModalEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    saveLoaded: isSaveUserLoaded(state),
    saveError: isSaveUserError(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    saveUser: saveVirtualOperatorUserRequest,
  })(PbxVirtualOperatorUserModalEdit)
);
