export const SAVE_ENTERPRISE_FESTIVITIES_REQUEST =
  'SAVE_ENTERPRISE_FESTIVITIES_REQUEST';
export const SAVE_ENTERPRISE_FESTIVITIES_SUCCESS =
  'SAVE_ENTERPRISE_FESTIVITIES_SUCCESS';
export const SAVE_ENTERPRISE_FESTIVITIES_FAILURE =
  'SAVE_ENTERPRISE_FESTIVITIES_FAILURE';
export const SAVE_OPENING_REQUEST = 'SAVE_OPENING_REQUEST';
export const SAVE_OPENING_SUCCESS = 'SAVE_OPENING_SUCCESS';
export const SAVE_OPENING_FAILURE = 'SAVE_OPENING_FAILURE';
export const FETCH_VIRTUAL_OPERATOR_USERS_REQUEST =
  'FETCH_VIRTUAL_OPERATOR_USERS_REQUEST';
export const FETCH_VIRTUAL_OPERATOR_USERS_SUCCESS =
  'FETCH_VIRTUAL_OPERATOR_USERS_SUCCESS';
export const FETCH_VIRTUAL_OPERATOR_USERS_FAILURE =
  'FETCH_VIRTUAL_OPERATOR_USERS_FAILURE';
export const SAVE_VIRTUAL_OPERATOR_USER_REQUEST =
  'SAVE_VIRTUAL_OPERATOR_USER_REQUEST';
export const SAVE_VIRTUAL_OPERATOR_USER_SUCCESS =
  'SAVE_VIRTUAL_OPERATOR_USER_SUCCESS';
export const SAVE_VIRTUAL_OPERATOR_USER_FAILURE =
  'SAVE_VIRTUAL_OPERATOR_USER_FAILURE';
export const FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST =
  'FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST';
export const FETCH_VIRTUAL_OPERATOR_OFFICES_SUCCESS =
  'FETCH_VIRTUAL_OPERATOR_OFFICES_SUCCESS';
export const FETCH_VIRTUAL_OPERATOR_OFFICES_FAILURE =
  'FETCH_VIRTUAL_OPERATOR_OFFICES_FAILURE';
export const SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST =
  'SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST';
export const SAVE_VIRTUAL_OPERATOR_OFFICE_SUCCESS =
  'SAVE_VIRTUAL_OPERATOR_OFFICE_SUCCESS';
export const SAVE_VIRTUAL_OPERATOR_OFFICE_FAILURE =
  'SAVE_VIRTUAL_OPERATOR_OFFICE_FAILURE';
export const FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST =
  'FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST';
export const FETCH_VIRTUAL_OPERATOR_MESSAGES_SUCCESS =
  'FETCH_VIRTUAL_OPERATOR_MESSAGES_SUCCESS';
export const FETCH_VIRTUAL_OPERATOR_MESSAGES_FAILURE =
  'FETCH_VIRTUAL_OPERATOR_MESSAGES_FAILURE';
export const SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST =
  'SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST';
export const SAVE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS =
  'SAVE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS';
export const SAVE_VIRTUAL_OPERATOR_MESSAGE_FAILURE =
  'SAVE_VIRTUAL_OPERATOR_MESSAGE_FAILURE';
export const DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST =
  'DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST';
export const DELETE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS =
  'DELETE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS';
export const DELETE_VIRTUAL_OPERATOR_MESSAGE_FAILURE =
  'DELETE_VIRTUAL_OPERATOR_MESSAGE_FAILURE';
