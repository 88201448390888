export const getEnterpriseFestivities = (state) =>
  state.virtualOperator.enterpriseFestivities;
export const getEnterpriseFestivitiesMessage = (state) =>
  state.virtualOperator.enterpriseFestivitiesMessage;
export const getEnterpriseOpeningTable = (state) =>
  state.virtualOperator.openingTable;
export const getEnterpriseOpeningMessage = (state) =>
  state.virtualOperator.openingMessage;
export const isSaveFestivitiesLoaded = (state) =>
  state.virtualOperator.saveFestivitiesLoaded;
export const isSaveFestivitiesError = (state) =>
  state.virtualOperator.saveFestivitiesError;
export const isSaveOpeningLoaded = (state) =>
  state.virtualOperator.saveOpeningLoaded;
export const isSaveOpeningError = (state) =>
  state.virtualOperator.saveOpeningError;
export const virtualOperatorUsersLoaded = (state) =>
  state.virtualOperator.fetchUsersLoaded;
export const virtualOperatorUsersError = (state) =>
  state.virtualOperator.fetchUsersError;
export const getVirtualOperatorUsers = (state) => state.virtualOperator.users;
export const getVirtualOperatorUsersTotal = (state) =>
  state.virtualOperator.totalUsers;
export const isSaveUserLoaded = (state) => state.virtualOperator.saveUserLoaded;
export const isSaveUserError = (state) => state.virtualOperator.saveUserError;
export const virtualOperatorOfficesLoaded = (state) =>
  state.virtualOperator.fetchOfficesLoaded;
export const virtualOperatorOfficesError = (state) =>
  state.virtualOperator.fetchOfficesError;
export const getVirtualOperatorOffices = (state) =>
  state.virtualOperator.offices;
export const getVirtualOperatorOfficeTotal = (state) =>
  state.virtualOperator.totalOffices;
export const isSaveOfficeLoaded = (state) =>
  state.virtualOperator.saveOfficeLoaded;
export const isSaveOfficeError = (state) =>
  state.virtualOperator.saveOfficeError;
export const virtualOperatorMessagesLoaded = (state) =>
  state.virtualOperator.fetchMessagesLoaded;
export const virtualOperatorMessagesError = (state) =>
  state.virtualOperator.fetchMessagesError;
export const getVirtualOperatorMessages = (state) =>
  state.virtualOperator.messages;
export const getVirtualOperatorMessagesTotal = (state) =>
  state.virtualOperator.totalMessages;
export const isSaveMessageLoaded = (state) =>
  state.virtualOperator.saveMessageLoaded;
export const isSaveMessageError = (state) =>
  state.virtualOperator.saveMessageError;
export const isDeleteMessageLoaded = (state) =>
  state.virtualOperator.deleteMessageLoaded;
export const isDeleteMessageError = (state) =>
  state.virtualOperator.deleteMessageError;
