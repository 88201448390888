import { request } from './request';
import auth from './auth/api';
import calendar from './calendar/api';
import chat from './chat/api';
import documents from './documents/api';
import groups from './groups/api';
import phone from './phone/api';
import files from './files/api';
import hotel from './hotel/api';
import wiki from './wiki/api';
import payments from './payments/api';
import contacts from './contacts/api';
import stickyNotes from './sticky/api';
import grants from './grants/api';
import me from './me/api';
import notifications from './notifications/api';
import ext from './ext/api';
import mailoffice from './mailoffice/api';
import videocalls from './videocalls/api';
import fax from './fax/api';
import vbox from './vbox/api';
import settings from './settings/api';
import logo from './logo/api';
import presence from './presence/api';
import users from './users/api';
import ynConf from '../conf';
import tickets from './tickets/api';
import cdr from './cdr/api';
import filebox from './filebox/api';
import usersProfiles from './usersProfiles/api';
import phoneRules from './phoneRules/api';
import workingPlans from './workingPlans/api';
import sms from './sms/api';
import associations from './associations/api';
import externalUrls from './externalUrl/api';
import virtualOperator from './virtualOperator/api';

export default {
  auth,
  calendar,
  chat,
  contacts,
  documents,
  ext,
  fax,
  files,
  grants,
  groups,
  hotel,
  logo,
  mailoffice,
  me,
  notifications,
  payments,
  phone,
  presence,
  settings,
  stickyNotes,
  users,
  videocalls,
  vbox,
  wiki,
  tickets,
  cdr,
  filebox,
  usersProfiles,
  phoneRules,
  workingPlans,
  sms,
  associations,
  externalUrls,
  virtualOperator,
  getHelp: (path) =>
    request(
      {
        url: path,
        method: 'GET',
      },
      { baseUrl: `${ynConf.clientBaseUrl}/` }
    ),
  getWeather: ({ place, lang }) =>
    request(
      {
        url: `${ynConf.openweather.path}&q=${place}&appid=${ynConf.openweather.appid}&lang=${lang}`,
        method: 'GET',
      },
      { baseUrl: ynConf.openweather.baseUrl }
    ),
};
