import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { fetchVirtualOperatorMessagesRequest } from '../../../../js/virtualOperator/actions';
import {
  getVirtualOperatorMessages,
  getVirtualOperatorMessagesTotal,
  isDeleteMessageError,
  isDeleteMessageLoaded,
  virtualOperatorMessagesError,
  virtualOperatorMessagesLoaded,
} from '../../../../js/virtualOperator/selectors';
import { Pagination } from 'reactstrap';
import GlobalErrorPage from '../../../pages/GlobalErrorPage';
import Loading from '../../../../components/Loading';
import HelpButton from '../../../../components/HelpButton';
import Filters from '../../../../components/Filters';
import Search from '../../../../components/formControls/Search';
import Button from '../../../../components/formControls/Button';
import { hasGrants } from '../../../../js/me/selectors';
import PbxVirtualOperatorMessageModalEdit from './PbxVirtualOperatorMessageModalEdit';
import PbxVirtualOperatorMessageRow from './PbxVirtualOperatorMessageRow';
import ToastMessage from '../../../../components/messages/ToastMessage';

const PAGE_SIZE = 10;

const messages = defineMessages({
  name: {
    id: 'PbxVirtualOperatorMessagesList.name',
    defaultMessage: 'Name',
  },
  text: {
    id: 'PbxVirtualOperatorMessagesList.text',
    defaultMessage: 'Text',
  },
  searchPlaceHolder: {
    id: 'PbxVirtualOperatorMessagesList.searchPlaceHolder',
    defaultMessage: 'Filter messages by name',
  },
  newMessage: {
    id: 'PbxVirtualOperatorMessagesList.newMessage',
    defaultMessage: 'New message',
  },
  noRow: {
    id: 'PbxVirtualOperatorMessagesList.noRow',
    defaultMessage: 'There are no messages',
  },
  errorDeleting: {
    id: 'PbxVirtualOperatorMessagesList.errorDeleting',
    defaultMessage: 'The message has not been deleted due to an error.',
  },
});

class PbxVirtualOperatorMessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalInsertOpen: false,
      currentPage: 0,
      name: null,
    };
  }

  componentDidMount() {
    const params = {
      page: 0,
      pageSize: PAGE_SIZE,
      name: null,
    };
    this.props.fetch(params);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.deleteLoaded &&
      !prevProps.deleteLoaded &&
      !this.props.deleteError
    ) {
      this.props.fetch({
        page: 0,
        pageSize: PAGE_SIZE,
        name: this.state.name,
      });
    }
  }

  toggleInsertModal = () => {
    const { modalInsertOpen } = this.state;
    this.setState({
      modalInsertOpen: !modalInsertOpen,
    });
  };

  handleFilter = (name) => {
    this.setState({ name });
    const params = {
      page: 0,
      pageSize: PAGE_SIZE,
      name,
    };
    this.props.fetch(params);
  };

  handlePage = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
    const params = {
      page: pageNumber,
      pageSize: PAGE_SIZE,
      name: this.state.name,
    };
    this.props.fetch(params);
  };

  render() {
    const {
      canEdit,
      errorFetch,
      messageList,
      pages,
      loading,
      deleteError,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <div className="text-right mb-2">
          <HelpButton fileName="pbx-virtual-operator-messages" />
        </div>
        {deleteError && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.errorDeleting)}
          />
        )}
        <Filters>
          <div className="row pt-2 pb-2">
            <div className="col">
              <Search
                id="filterNameMessage"
                name="filterNameMessage"
                placeholder={formatMessage(messages.searchPlaceHolder)}
                onChange={this.handleFilter}
                text={this.state.name}
              />
            </div>
          </div>
        </Filters>
        {errorFetch ? (
          <GlobalErrorPage />
        ) : loading ? (
          <Loading />
        ) : (
          <>
            {canEdit && (
              <div className="text-right">
                <Button
                  className="btn btn-primary"
                  text={formatMessage(messages.newMessage)}
                  onClick={() => this.toggleInsertModal()}
                />
              </div>
            )}
            <div className="rounded border p-2 mt-2">
              <table className="table table-borderless table-hover">
                <thead
                  style={{
                    color: 'var(--yn-blue-darkest)',
                  }}
                >
                  <tr>
                    <th>{formatMessage(messages.name)}</th>
                    <th>{formatMessage(messages.text)}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {messageList.map((message) => (
                    <PbxVirtualOperatorMessageRow
                      key={message}
                      message={message}
                    />
                  ))}
                </tbody>
                {(!messageList || messageList.length === 0) && (
                  <tfoot>
                    <tr>
                      <td colSpan="3">{formatMessage(messages.noRow)}</td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </>
        )}
        {!loading && pages > 1 && (
          <Pagination
            pages={pages}
            currentPage={this.state.currentPage}
            handlePage={this.handlePage}
          />
        )}
        {this.state.modalInsertOpen && (
          <PbxVirtualOperatorMessageModalEdit
            isOpen={this.state.modalInsertOpen}
            onClose={this.toggleInsertModal}
            message={{}}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    canEdit: hasGrants(state, 'PBX_HOLIDAYS_WRITE'),
    loading: !virtualOperatorMessagesLoaded(state),
    errorFetch: virtualOperatorMessagesError(state),
    messageList: getVirtualOperatorMessages(state),
    pages: Math.ceil(getVirtualOperatorMessagesTotal(state) / PAGE_SIZE),
    deleteError: isDeleteMessageError(state),
    deleteLoaded: isDeleteMessageLoaded(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchVirtualOperatorMessagesRequest,
  })(PbxVirtualOperatorMessagesList)
);
