import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { hasGrants } from '../../../../js/me/selectors';
import ToolboxIcon from '../../../../components/icons/ToolboxIcon';
import PbxVirtualOperatorMessageModalEdit from './PbxVirtualOperatorMessageModalEdit';
import { showConfirmModal } from '../../../../js/confirm/actions';
import { deleteMessage } from '../../../../js/virtualOperator/sagas';
import { deleteVirtualOperatorMessageRequest } from '../../../../js/virtualOperator/actions';

const messages = defineMessages({
  editMessage: {
    id: 'PbxVirtualOperatorMessageRow.tooltip.editMessage',
    defaultMessage: 'Edit',
  },
  deleteMessage: {
    id: 'PbxVirtualOperatorMessageRow.tooltip.deleteMessage',
    defaultMessage: 'Delete',
  },
  deleteConfirmation: {
    id: 'PbxVirtualOperatorMessageRow.deleteConfirmation',
    defaultMessage: 'Are you sure you want to delete this message?',
  },
});

class PbxVirtualOperatorMessageRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEditOpen: false,
    };
  }

  toggleEditModal = () => {
    const { modalEditOpen } = this.state;
    this.setState({
      modalEditOpen: !modalEditOpen,
    });
  };

  handleDelete = () => {
    this.props.confirm({
      message: this.props.intl.formatMessage(messages.deleteConfirmation),
      modalAction: () => this.props.deleteMessage(this.props.message.id),
    });
  };

  render() {
    const {
      message,
      canEdit,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <tr>
          <td>{message.name}</td>
          <td>{message.text}</td>
          <td>
            {canEdit && (
              <>
                <span onClick={() => this.toggleEditModal()}>
                  <ToolboxIcon name="pencil-write-2">
                    {formatMessage(messages.editMessage)}
                  </ToolboxIcon>
                </span>
                <span onClick={() => this.handleDelete()}>
                  <ToolboxIcon name="bin-1">
                    {formatMessage(messages.deleteMessage)}
                  </ToolboxIcon>
                </span>
              </>
            )}
          </td>
        </tr>
        {this.state.modalEditOpen && (
          <PbxVirtualOperatorMessageModalEdit
            isOpen={this.state.modalEditOpen}
            onClose={this.toggleEditModal}
            message={message}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    canEdit: hasGrants(state, 'PBX_HOLIDAYS_WRITE'),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    confirm: showConfirmModal,
    deleteMessage: deleteVirtualOperatorMessageRequest,
  })(PbxVirtualOperatorMessageRow)
);
