import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import Utils from '../../../../js/lib/utils';
import { getCalendarFestivitiesSettings } from '../../../../js/calendar/selectors';
import LinkButton from '../../../../components/LinkButton';
import Label from '../../../../components/formControls/Label';
import Select2 from '../../../../components/formControls/Select2';
import { getVirtualOperatorMessages } from '../../../../js/virtualOperator/selectors';

const messages = defineMessages({
  CAPODANNO: {
    id: 'FestivitiesComponent.CAPODANNO.label',
    defaultMessage: 'First of the year',
  },
  EPIFANIA: {
    id: 'FestivitiesComponent.EPIFANIA.label',
    defaultMessage: 'Epiphany',
  },
  LIBERAZIONE: {
    id: 'FestivitiesComponent.LIBERAZIONE.label',
    defaultMessage: 'Freedom day',
  },
  LAVORATORI: {
    id: 'FestivitiesComponent.LAVORATORI.label',
    defaultMessage: 'Workers day',
  },
  REPUBBLICA: {
    id: 'FestivitiesComponent.REPUBBLICA.label',
    defaultMessage: 'Republic day',
  },
  FERRAGOSTO: {
    id: 'FestivitiesComponent.FERRAGOSTO.label',
    defaultMessage: 'Mid-August',
  },
  OGNISSANTI: {
    id: 'FestivitiesComponent.OGNISSANTI.label',
    defaultMessage: 'All Saints',
  },
  IMMACOLATA: {
    id: 'FestivitiesComponent.IMMACOLATA.label',
    defaultMessage: 'Immaculate Conception',
  },
  NATALE: {
    id: 'FestivitiesComponent.NATALE.label',
    defaultMessage: 'Christmas',
  },
  SANTO_STEFANO: {
    id: 'FestivitiesComponent.SANTO_STEFANO.label',
    defaultMessage: 'Day after Christmas',
  },
  PASQUA: {
    id: 'FestivitiesComponent.PASQUA.label',
    defaultMessage: 'Easter',
  },
  PASQUETTA: {
    id: 'FestivitiesComponent.PASQUETTA.label',
    defaultMessage: 'Day after Easter',
  },
  selectAll: {
    id: 'FestivitiesComponent.selectAll.label',
    defaultMessage: 'Select all',
  },
  message: {
    id: 'FestivitiesComponent.message',
    defaultMessage: 'Message',
  },
});

const FestivitiesComponent = ({
  festivities,
  chosenMessage,
  customerFestivities,
  createdMessages,
  handleEnabled,
  handleAllEnabled,
  handleMessageSelection,
  intl: { formatMessage },
}) => {
  const parsedFestivities = Utils.retrieveFestivities(
    [],
    customerFestivities.added,
    new Date().getFullYear()
  );

  const messagesOptions = [
    {
      value: null,
      label: '',
    },
  ];
  createdMessages.forEach((message) => {
    messagesOptions.push({
      value: message.id,
      label: message.name,
    });
  });

  return (
    <>
      <div className="row no-gutters">
        <div className="col pb-3">
          <LinkButton onClick={handleAllEnabled}>
            {formatMessage(messages.selectAll)}
          </LinkButton>
        </div>
      </div>
      {parsedFestivities.map((festivity, index) => (
        <div key={index} className="custom-control custom-checkbox">
          <input
            type="checkbox"
            id={festivity.description}
            name={festivity.description}
            className="custom-control-input"
            checked={festivities[festivity.description]}
            onChange={(e) => handleEnabled(e, festivity.description)}
          />
          <label
            className="custom-control-label"
            htmlFor={festivity.description}
          >
            {messages[festivity.description]
              ? formatMessage(messages[festivity.description])
              : festivity.description}
          </label>
        </div>
      ))}
      <div className="row">
        <div className="col-xl-12 p-3">
          <Label for="message">{formatMessage(messages.message)}</Label>
          <Select2
            id="message"
            name="message"
            options={messagesOptions}
            isClearable
            onChange={handleMessageSelection}
            value={chosenMessage}
            isDisabled={
              !Object.keys(festivities).some((key) => festivities[key])
            }
          />
        </div>
      </div>
    </>
  );
};

FestivitiesComponent.propTypes = {
  festivities: PropTypes.object.isRequired,
  message: PropTypes.number,
  handleEnabled: PropTypes.func.isRequired,
  handleAllEnabled: PropTypes.func.isRequired,
  handleMessageSelection: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    customerFestivities: getCalendarFestivitiesSettings(state),
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(connect(mapStateToProps)(FestivitiesComponent));
