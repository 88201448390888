import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import FormSectionTitle from '../../../../components/FormSectionTitle';
import Button from '../../../../components/formControls/Button';
import {
  getEnterpriseOpeningMessage,
  getEnterpriseOpeningTable,
  getVirtualOperatorMessages,
} from '../../../../js/virtualOperator/selectors';
import OpeningModalEdit from './PbxVirtualOperatorOpeningModalEdit';
import { hasGrants } from '../../../../js/me/selectors';

const messages = defineMessages({
  opening: {
    id: 'PbxVirtualOperatorCustomerOpening.opening.label',
    defaultMessage: 'Opening hours',
  },
  noOpening: {
    id: 'PbxVirtualOperatorCustomerOpening.noOpening.label',
    defaultMessage: 'No opening hours',
  },
  buttonEdit: {
    id: 'PbxVirtualOperatorCustomerOpening.buttonEdit',
    defaultMessage: 'Edit',
  },
  0: {
    id: 'PbxVirtualOperatorCustomerOpening.0',
    defaultMessage: 'Sunday',
  },
  1: {
    id: 'PbxVirtualOperatorCustomerOpening.1',
    defaultMessage: 'Monday',
  },
  2: {
    id: 'PbxVirtualOperatorCustomerOpening.2',
    defaultMessage: 'Tuesday',
  },
  3: {
    id: 'PbxVirtualOperatorCustomerOpening.3',
    defaultMessage: 'Wednesday',
  },
  4: {
    id: 'PbxVirtualOperatorCustomerOpening.4',
    defaultMessage: 'Thursday',
  },
  5: {
    id: 'PbxVirtualOperatorCustomerOpening.5',
    defaultMessage: 'Friday',
  },
  6: {
    id: 'PbxVirtualOperatorCustomerOpening.6',
    defaultMessage: 'Saturday',
  },
  message: {
    id: 'PbxVirtualOperatorCustomerFestivities.message.label',
    defaultMessage: 'Message',
  },
});

class PbxVirtualOperatorCustomerOpening extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpeningOpen: false,
    };
  }

  toggleOpeningModal = () => {
    const { modalOpeningOpen } = this.state;
    this.setState({
      modalOpeningOpen: !modalOpeningOpen,
    });
  };

  render() {
    const {
      openingTable,
      canEdit,
      createdMessages,
      openingMessage,
      intl: { formatMessage },
    } = this.props;

    const selectedOpening = [];

    if (openingTable) {
      for (let i = 0; i < 7; i += 1) {
        if (openingTable[i] && openingTable[i].entrance1) {
          let message = `${openingTable[i].entrance1}-${openingTable[i].exit1}`;
          if (openingTable[i].entrance2) {
            message += `, ${openingTable[i].entrance2}-${openingTable[i].exit2}`;
          }
          selectedOpening.push(message);
        } else {
          selectedOpening.push(null);
        }
      }
    }
    return (
      <>
        <div>
          <FormSectionTitle className="mt-3 mb-3">
            {formatMessage(messages.opening)}
          </FormSectionTitle>
          {selectedOpening.filter((item) => item).length ? (
            <>
              {selectedOpening.map((day, index) =>
                day ? (
                  <div key={index}>
                    <span className="font-weight-bold mr-2">
                      {formatMessage(messages[index])}:
                    </span>
                    <span>{day}</span>
                  </div>
                ) : (
                  <></>
                )
              )}
              {openingMessage && (
                <div>
                  <span className="font-weight-bold">
                    {formatMessage(messages.message)}:
                  </span>
                  <span>
                    {
                      createdMessages.find((item) => item.id === openingMessage)
                        ?.name
                    }
                  </span>
                </div>
              )}
            </>
          ) : (
            <span>{formatMessage(messages.noOpening)}</span>
          )}
          {canEdit && (
            <div className="pt-3 text-center">
              <Button
                className="btn btn-primary"
                text={formatMessage(messages.buttonEdit)}
                onClick={this.toggleOpeningModal}
              />
            </div>
          )}
        </div>
        {this.state.modalOpeningOpen && (
          <OpeningModalEdit
            isOpen={this.state.modalOpeningOpen}
            onClose={this.toggleOpeningModal}
            openingTable={openingTable}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    openingTable: getEnterpriseOpeningTable(state),
    canEdit: hasGrants(state, 'PBX_HOLIDAYS_WRITE'),
    openingMessage: getEnterpriseOpeningMessage(state),
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(
  connect(mapStateToProps)(PbxVirtualOperatorCustomerOpening)
);
