import React from 'react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import HelpButton from '../../../../components/HelpButton';
import PbxVirtualOperator from './PbxVirtualOperator';
import PbxVirtualOperatorMessagesList from './PbxVirtualOperatorMessagesList';

const PbxVirtualOperatorMessagesPage = (props) => (
  <PbxVirtualOperator scrolling>
    <div className="text-right">
      <HelpButton fileName="pbx-settings-virtual-operator" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <PbxVirtualOperatorMessagesList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PbxVirtualOperator>
);

export default PbxVirtualOperatorMessagesPage;
