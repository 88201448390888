import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import HelpButton from '../../../../components/HelpButton';
import PbxVirtualOperator from './PbxVirtualOperator';
import PbxVirtualOperatorCustomerOpening from './PbxVirtualOperatorCustomerOpening';
import PbxVirtualOperatorCustomerFestivities from './PbxVirtualOperatorCustomerFestivities';
import { fetchVirtualOperatorMessagesRequest } from '../../../../js/virtualOperator/actions';

class PbxVirtualOperatorCustomerPage extends Component {
  componentDidMount() {
    const params = {
      name: null,
    };
    this.props.fetch(params);
  }

  render() {
    return (
      <PbxVirtualOperator scrolling>
        <div className="text-right">
          <HelpButton fileName="pbx-settings-virtual-operator" />
        </div>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <PbxVirtualOperatorCustomerFestivities {...this.props} />
            <PbxVirtualOperatorCustomerOpening {...this.props} />
          </React.Suspense>
        </ErrorBoundary>
      </PbxVirtualOperator>
    );
  }
}

export default connect(null, {
  fetch: fetchVirtualOperatorMessagesRequest,
})(PbxVirtualOperatorCustomerPage);
