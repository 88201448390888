import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../../components/formControls/Button';
import Utils from '../../../../js/lib/utils';
import {
  getEnterpriseFestivities,
  getEnterpriseFestivitiesMessage,
  getVirtualOperatorMessages,
  isSaveFestivitiesError,
  isSaveFestivitiesLoaded,
} from '../../../../js/virtualOperator/selectors';
import { saveEnterpriseFestivitiesRequest } from '../../../../js/virtualOperator/actions';
import ToastMessage from '../../../../components/messages/ToastMessage';
import FestivitiesComponent from './PbxVirtualOperatorFestivitiesComponent';
import { getCalendarFestivitiesSettings } from '../../../../js/calendar/selectors';

const messages = defineMessages({
  title: {
    id: 'FestivitiesModalEdit.title',
    defaultMessage: 'Festivity',
  },
  save: {
    id: 'FestivitiesModalEdit.button.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'FestivitiesModalEdit.button.cancel',
    defaultMessage: 'Cancel',
  },
  errorSaving: {
    id: 'FestivitiesModalEdit.errorSaving',
    defaultMessage: 'Error saving',
  },
});

class FestivitiesModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      festivities: {},
      message: {
        value: props.festivitiesMessage,
        label: props.festivitiesMessage
          ? props.createdMessages.find(
              (message) => message.id === props.festivitiesMessage
            ).name
          : '',
      },
    };
    const parsedFestivities = Utils.retrieveFestivities(
      [],
      props.customerFestivities.added,
      new Date().getFullYear()
    );
    for (let i = 0; i < parsedFestivities.length; i += 1) {
      this.state.festivities[parsedFestivities[i].description] = !!(
        props.enterpriseFestivities &&
        (props.enterpriseFestivities.custom.indexOf(
          parsedFestivities[i].description
        ) >= 0 ||
          props.enterpriseFestivities.standard.indexOf(
            parsedFestivities[i].description
          ) >= 0)
      );
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.loaded && !prevProps.loaded && !this.props.error) {
      this.props.onClose();
    }
  };

  handleEnabled = (e, name) => {
    this.setState(
      {
        festivities: {
          ...this.state.festivities,
          [name]: e.target.checked,
        },
      },
      () => {
        if (
          !Object.keys(this.state.festivities).some(
            (key) => this.state.festivities[key]
          )
        ) {
          this.setState({ message: { value: null, label: '' } });
        }
      }
    );
  };

  handleAllEnabled = () => {
    const festivities = {};
    Object.keys(this.state.festivities).forEach((key) => {
      festivities[key] = true;
    });
    this.setState({ festivities });
  };

  handleMessageSelection = (selected) => {
    this.setState({
      ...this.state,
      message: selected,
    });
  };

  save = () => {
    const parsedFestivities = Utils.retrieveFestivities(
      [],
      this.props.customerFestivities.added,
      new Date().getFullYear()
    );
    const standard = [];
    const custom = [];
    parsedFestivities.forEach((festivity) => {
      if (this.state.festivities[festivity.description]) {
        if (
          this.props.customerFestivities.added
            .map((item) => item.description)
            .indexOf(festivity.description) >= 0
        ) {
          custom.push(festivity.description);
        } else {
          standard.push(festivity.description);
        }
      }
    });
    this.props.saveEnterpriseFestivities({
      festivities: { standard, custom },
      message: this.state.message ? this.state.message.value : null,
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      loaded,
      error,
      intl: { formatMessage },
    } = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={onClose}>
          {formatMessage(messages.title)}
        </ModalHeader>

        <ModalBody>
          {error && (
            <ToastMessage
              type="danger"
              text={formatMessage(messages.errorSaving)}
              closeTimeout={5000}
            />
          )}
          <FestivitiesComponent
            festivities={this.state.festivities}
            chosenMessage={this.state.message}
            handleEnabled={this.handleEnabled}
            handleAllEnabled={this.handleAllEnabled}
            handleMessageSelection={this.handleMessageSelection}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.save}
            text={formatMessage(messages.save)}
            loading={!loaded}
          />
          <button
            type="button"
            className="btn btn-outline-primary ml-2"
            onClick={onClose}
          >
            {formatMessage(messages.cancel)}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

FestivitiesModalEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    customerFestivities: getCalendarFestivitiesSettings(state),
    enterpriseFestivities: getEnterpriseFestivities(state),
    festivitiesMessage: getEnterpriseFestivitiesMessage(state),
    loaded: isSaveFestivitiesLoaded(state),
    error: isSaveFestivitiesError(state),
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    saveEnterpriseFestivities: saveEnterpriseFestivitiesRequest,
  })(FestivitiesModalEdit)
);
