import {
  DELETE_VIRTUAL_OPERATOR_MESSAGE_FAILURE,
  DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  DELETE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS,
  FETCH_VIRTUAL_OPERATOR_MESSAGES_FAILURE,
  FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST,
  FETCH_VIRTUAL_OPERATOR_MESSAGES_SUCCESS,
  FETCH_VIRTUAL_OPERATOR_OFFICES_FAILURE,
  FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST,
  FETCH_VIRTUAL_OPERATOR_OFFICES_SUCCESS,
  FETCH_VIRTUAL_OPERATOR_USERS_FAILURE,
  FETCH_VIRTUAL_OPERATOR_USERS_REQUEST,
  FETCH_VIRTUAL_OPERATOR_USERS_SUCCESS,
  SAVE_ENTERPRISE_FESTIVITIES_FAILURE,
  SAVE_ENTERPRISE_FESTIVITIES_REQUEST,
  SAVE_ENTERPRISE_FESTIVITIES_SUCCESS,
  SAVE_OPENING_FAILURE,
  SAVE_OPENING_REQUEST,
  SAVE_OPENING_SUCCESS,
  SAVE_VIRTUAL_OPERATOR_MESSAGE_FAILURE,
  SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  SAVE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS,
  SAVE_VIRTUAL_OPERATOR_OFFICE_FAILURE,
  SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST,
  SAVE_VIRTUAL_OPERATOR_OFFICE_SUCCESS,
  SAVE_VIRTUAL_OPERATOR_USER_FAILURE,
  SAVE_VIRTUAL_OPERATOR_USER_REQUEST,
  SAVE_VIRTUAL_OPERATOR_USER_SUCCESS,
} from './types';

export const saveEnterpriseFestivitiesRequest = (data) => ({
  type: SAVE_ENTERPRISE_FESTIVITIES_REQUEST,
  data,
});
export const saveEnterpriseFestivitiesSuccess = (data) => ({
  type: SAVE_ENTERPRISE_FESTIVITIES_SUCCESS,
  data,
});
export const saveEnterpriseFestivitiesFailure = (error) => ({
  type: SAVE_ENTERPRISE_FESTIVITIES_FAILURE,
  error,
});
export const saveOpeningRequest = (data) => ({
  type: SAVE_OPENING_REQUEST,
  data,
});
export const saveOpeningSuccess = (data) => ({
  type: SAVE_OPENING_SUCCESS,
  data,
});
export const saveOpeningFailure = (error) => ({
  type: SAVE_OPENING_FAILURE,
  error,
});
export const fetchVirtualOperatorUsersRequest = (params) => ({
  type: FETCH_VIRTUAL_OPERATOR_USERS_REQUEST,
  params,
});
export const fetchVirtualOperatorUsersSuccess = (data) => ({
  type: FETCH_VIRTUAL_OPERATOR_USERS_SUCCESS,
  data,
});
export const fetchVirtualOperatorUsersFailure = (error) => ({
  type: FETCH_VIRTUAL_OPERATOR_USERS_FAILURE,
  error,
});
export const saveVirtualOperatorUserRequest = (data) => ({
  type: SAVE_VIRTUAL_OPERATOR_USER_REQUEST,
  data,
});
export const saveVirtualOperatorUserSuccess = (data) => ({
  type: SAVE_VIRTUAL_OPERATOR_USER_SUCCESS,
  data,
});
export const saveVirtualOperatorUserFailure = (error) => ({
  type: SAVE_VIRTUAL_OPERATOR_USER_FAILURE,
  error,
});
export const fetchVirtualOperatorOfficesRequest = (params) => ({
  type: FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST,
  params,
});
export const fetchVirtualOperatorOfficesSuccess = (data) => ({
  type: FETCH_VIRTUAL_OPERATOR_OFFICES_SUCCESS,
  data,
});
export const fetchVirtualOperatorOfficesFailure = (error) => ({
  type: FETCH_VIRTUAL_OPERATOR_OFFICES_FAILURE,
  error,
});
export const saveVirtualOperatorOfficeRequest = (data) => ({
  type: SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST,
  data,
});
export const saveVirtualOperatorOfficeSuccess = (data) => ({
  type: SAVE_VIRTUAL_OPERATOR_OFFICE_SUCCESS,
  data,
});
export const saveVirtualOperatorOfficeFailure = (error) => ({
  type: SAVE_VIRTUAL_OPERATOR_OFFICE_FAILURE,
  error,
});
export const fetchVirtualOperatorMessagesRequest = (params) => ({
  type: FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST,
  params,
});
export const fetchVirtualOperatorMessagesSuccess = (data) => ({
  type: FETCH_VIRTUAL_OPERATOR_MESSAGES_SUCCESS,
  data,
});
export const fetchVirtualOperatorMessagesFailure = (error) => ({
  type: FETCH_VIRTUAL_OPERATOR_MESSAGES_FAILURE,
  error,
});
export const saveVirtualOperatorMessageRequest = (data) => ({
  type: SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  data,
});
export const saveVirtualOperatorMessageSuccess = (data) => ({
  type: SAVE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS,
  data,
});
export const saveVirtualOperatorMessageFailure = (error) => ({
  type: SAVE_VIRTUAL_OPERATOR_MESSAGE_FAILURE,
  error,
});
export const deleteVirtualOperatorMessageRequest = (id) => ({
  type: DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  id,
});
export const deleteVirtualOperatorMessageSuccess = (id) => ({
  type: DELETE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS,
  id,
});
export const deleteVirtualOperatorMessageFailure = (error) => ({
  type: DELETE_VIRTUAL_OPERATOR_MESSAGE_FAILURE,
  error,
});
