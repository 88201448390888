import { LOGOUT_SUCCESS } from '../auth/types';
import { FETCH_CUSTOMER_SETTINGS_SUCCESS } from '../me/types';
import {
  DELETE_VIRTUAL_OPERATOR_MESSAGE_FAILURE,
  DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  DELETE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS,
  FETCH_VIRTUAL_OPERATOR_MESSAGES_FAILURE,
  FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST,
  FETCH_VIRTUAL_OPERATOR_MESSAGES_SUCCESS,
  FETCH_VIRTUAL_OPERATOR_OFFICES_FAILURE,
  FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST,
  FETCH_VIRTUAL_OPERATOR_OFFICES_SUCCESS,
  FETCH_VIRTUAL_OPERATOR_USERS_FAILURE,
  FETCH_VIRTUAL_OPERATOR_USERS_REQUEST,
  FETCH_VIRTUAL_OPERATOR_USERS_SUCCESS,
  SAVE_ENTERPRISE_FESTIVITIES_FAILURE,
  SAVE_ENTERPRISE_FESTIVITIES_REQUEST,
  SAVE_ENTERPRISE_FESTIVITIES_SUCCESS,
  SAVE_OPENING_FAILURE,
  SAVE_OPENING_REQUEST,
  SAVE_OPENING_SUCCESS,
  SAVE_VIRTUAL_OPERATOR_MESSAGE_FAILURE,
  SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST,
  SAVE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS,
  SAVE_VIRTUAL_OPERATOR_OFFICE_FAILURE,
  SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST,
  SAVE_VIRTUAL_OPERATOR_OFFICE_SUCCESS,
  SAVE_VIRTUAL_OPERATOR_USER_FAILURE,
  SAVE_VIRTUAL_OPERATOR_USER_REQUEST,
  SAVE_VIRTUAL_OPERATOR_USER_SUCCESS,
} from './types';

const initialState = {
  saveFestivitiesLoaded: true,
  saveFestivitiesError: false,
  enterpriseFestivities: [],
  enterpriseFestivitiesMessage: null,
  saveOpeningLoaded: true,
  saveOpeningError: false,
  openingTable: {},
  openingMessage: null,
  fetchUsersLoaded: true,
  fetchUsersError: false,
  users: [],
  saveUserLoaded: true,
  saveUserError: false,
  fetchOfficesLoaded: true,
  fetchOfficesError: false,
  offices: [],
  saveOfficeLoaded: true,
  saveOfficeError: false,
  fetchMessagesLoaded: true,
  fetchMessagesError: false,
  messages: [],
  saveMessageLoaded: true,
  saveMessageError: null,
  deleteMessageError: false,
  deleteMessageLoaded: true,
};

export default function videocalls(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;

    case FETCH_CUSTOMER_SETTINGS_SUCCESS: {
      return {
        ...state,
        enterpriseFestivities: action.customerSettings.festivities,
        enterpriseFestivitiesMessage:
          action.customerSettings.festivitiesMessage,
        openingTable: action.customerSettings.openingTable,
        openingMessage: action.customerSettings.openingMessage,
      };
    }

    case SAVE_ENTERPRISE_FESTIVITIES_REQUEST:
      return {
        ...state,
        saveFestivitiesLoaded: false,
        saveFestivitiesError: false,
      };

    case SAVE_ENTERPRISE_FESTIVITIES_SUCCESS:
      return {
        ...state,
        enterpriseFestivities: action.data.festivities,
        enterpriseFestivitiesMessage: action.data.message,
        saveFestivitiesLoaded: true,
        saveFestivitiesError: false,
      };

    case SAVE_ENTERPRISE_FESTIVITIES_FAILURE:
      return {
        ...state,
        saveFestivitiesLoaded: true,
        saveFestivitiesError: true,
      };

    case SAVE_OPENING_REQUEST:
      return {
        ...state,
        saveOpeningLoaded: false,
        saveOpeningError: false,
      };

    case SAVE_OPENING_SUCCESS:
      return {
        ...state,
        openingTable: action.data.opening,
        openingMessage: action.data.message,
        saveOpeningLoaded: true,
        saveOpeningError: false,
      };

    case SAVE_OPENING_FAILURE:
      return {
        ...state,
        saveOpeningLoaded: true,
        saveOpeningError: true,
      };

    case FETCH_VIRTUAL_OPERATOR_USERS_REQUEST:
      return {
        ...state,
        fetchUsersLoaded: false,
        fetchUsersError: false,
      };

    case FETCH_VIRTUAL_OPERATOR_USERS_SUCCESS:
      return {
        ...state,
        users: action.data.users,
        totalUsers: action.data.total,
        fetchUsersLoaded: true,
        fetchUsersError: false,
      };

    case FETCH_VIRTUAL_OPERATOR_USERS_FAILURE:
      return {
        ...state,
        fetchUsersLoaded: true,
        fetchUsersError: true,
      };

    case SAVE_VIRTUAL_OPERATOR_USER_REQUEST:
      return {
        ...state,
        saveUserLoaded: false,
        saveUserError: false,
      };

    case SAVE_VIRTUAL_OPERATOR_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id !== action.data.id) {
            return user;
          }
          return {
            ...user,
            pronunciation: action.data.data.pronunciation,
            transfer: action.data.data.transfer,
          };
        }),
        saveUserLoaded: true,
        saveUserError: false,
      };

    case SAVE_VIRTUAL_OPERATOR_USER_FAILURE:
      return {
        ...state,
        saveUserLoaded: true,
        saveUserError: true,
      };

    case FETCH_VIRTUAL_OPERATOR_OFFICES_REQUEST:
      return {
        ...state,
        fetchOfficesLoaded: false,
        fetchOfficesError: false,
      };

    case FETCH_VIRTUAL_OPERATOR_OFFICES_SUCCESS:
      return {
        ...state,
        offices: action.data.offices,
        totalOffices: action.data.total,
        fetchOfficesLoaded: true,
        fetchOfficesError: false,
      };

    case FETCH_VIRTUAL_OPERATOR_OFFICES_FAILURE:
      return {
        ...state,
        fetchOfficesLoaded: true,
        fetchOfficesError: true,
      };

    case SAVE_VIRTUAL_OPERATOR_OFFICE_REQUEST:
      return {
        ...state,
        saveOfficeLoaded: false,
        saveOfficeError: false,
      };

    case SAVE_VIRTUAL_OPERATOR_OFFICE_SUCCESS:
      return {
        ...state,
        offices: state.offices.map((office) => {
          if (office.route !== action.data.data.route) {
            return office;
          }
          return {
            ...office,
            id: action.data.id,
            festivities: action.data.data.festivities,
            festivitiesMessage: action.data.data.festivitiesMessage,
            opening: action.data.data.opening,
            openingMessage: action.data.data.openingMessage,
            name: action.data.data.name,
            pronunciation: action.data.data.pronunciation,
            transfer: action.data.data.transfer,
          };
        }),
        saveOfficeLoaded: true,
        saveOfficeError: false,
      };

    case SAVE_VIRTUAL_OPERATOR_OFFICE_FAILURE:
      return {
        ...state,
        saveOfficeLoaded: true,
        saveOfficeError: true,
      };

    case FETCH_VIRTUAL_OPERATOR_MESSAGES_REQUEST:
      return {
        ...state,
        fetchMessagesLoaded: false,
        fetchMessagesError: false,
      };

    case FETCH_VIRTUAL_OPERATOR_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.data.messages,
        totalMessages: action.data.total,
        fetchMessagesLoaded: true,
        fetchMessagesError: false,
      };

    case FETCH_VIRTUAL_OPERATOR_MESSAGES_FAILURE:
      return {
        ...state,
        fetchMessagesLoaded: true,
        fetchMessagesError: true,
      };

    case SAVE_VIRTUAL_OPERATOR_MESSAGE_REQUEST:
      return {
        ...state,
        saveMessageLoaded: false,
        saveMessageError: null,
      };

    case SAVE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS:
      let found = false;
      const messages = state.messages.map((message) => {
        if (message.id === action.data.id) {
          found = true;
          return {
            ...message,
            text: action.data.data.text,
            name: action.data.data.name,
          };
        }
        return message;
      });
      if (!found) {
        messages.push({
          id: action.data.id,
          text: action.data.data.text,
          name: action.data.data.name,
        });
      }
      return {
        ...state,
        messages,
        saveMessageLoaded: true,
        saveMessageError: null,
      };

    case SAVE_VIRTUAL_OPERATOR_MESSAGE_FAILURE:
      return {
        ...state,
        saveMessageLoaded: true,
        saveMessageError: action.error,
      };

    case DELETE_VIRTUAL_OPERATOR_MESSAGE_REQUEST:
      return {
        ...state,
        deleteMessageError: false,
        deleteMessageLoaded: false,
      };

    case DELETE_VIRTUAL_OPERATOR_MESSAGE_SUCCESS:
      return {
        ...state,
        deleteMessageError: false,
        deleteMessageLoaded: true,
      };

    case DELETE_VIRTUAL_OPERATOR_MESSAGE_FAILURE:
      return {
        ...state,
        deleteMessageError: true,
        deleteMessageLoaded: true,
      };

    default:
      return state;
  }
}
