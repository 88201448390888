import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { WorkingPlansEnums } from '../../../../js/workingPlans/WorkingPlansUtils';
import TimeInput from '../../../../components/formControls/TimeInput';

const messages = defineMessages({
  monday: {
    id: 'OpeningModalEditRow.label.monday',
    defaultMessage: 'Mon',
  },
  tuesday: {
    id: 'OpeningModalEditRow.label.tuesday',
    defaultMessage: 'Tue',
  },
  wednesday: {
    id: 'OpeningModalEditRow.label.wednesday',
    defaultMessage: 'Wed',
  },
  thursday: {
    id: 'OpeningModalEditRow.label.thursday',
    defaultMessage: 'Thu',
  },
  friday: {
    id: 'OpeningModalEditRow.label.friday',
    defaultMessage: 'Fri',
  },
  saturday: {
    id: 'OpeningModalEditRow.label.saturday',
    defaultMessage: 'Sat',
  },
  sunday: {
    id: 'OpeningModalEditRow.label.sunday',
    defaultMessage: 'Sun',
  },
});

class OpeningModalEditRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entrance1: props.data.entrance1,
      exit1: props.data.exit1,
      entrance2: props.data.entrance2,
      exit2: props.data.exit2,
      enabled: !!props.data.enabled,
    };
  }

  onChange = () => {
    this.props.onChangeDay(this.state);
  };

  handleSelected = (e) => {
    this.setState(
      {
        enabled: e.target.checked,
      },
      () => {
        this.onChange();
      }
    );
  };

  handleEntrance1 = (value) => {
    this.setState(
      {
        entrance1: value,
      },
      () => {
        this.onChange();
      }
    );
  };

  handleExit1 = (value) => {
    this.setState(
      {
        exit1: value,
      },
      () => {
        this.onChange();
      }
    );
  };

  handleEntrance2 = (value) => {
    this.setState(
      {
        entrance2: value,
      },
      () => {
        this.onChange();
      }
    );
  };

  handleExit2 = (value) => {
    this.setState(
      {
        exit2: value,
      },
      () => {
        this.onChange();
      }
    );
  };

  render() {
    const {
      day,
      intl: { formatMessage },
      errors,
    } = this.props;
    return (
      <tr>
        <td style={{ width: '65px', texAlign: 'center' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`day_${day}`}
              name={`day_${day}`}
              className="custom-control-input"
              checked={this.state.enabled}
              onChange={this.handleSelected}
            />
            <label className="custom-control-label" htmlFor={`day_${day}`} />
          </div>
        </td>
        <td>
          {formatMessage(messages[WorkingPlansEnums.daysOfWeekById[day]])}
        </td>
        <td>
          <TimeInput
            onChange={(e) => this.handleEntrance1(e.target.value)}
            value={this.state.enabled ? this.state.entrance1 : ''}
            name="entrance"
            id={`entrance_${day}`}
            disabled={!this.state.enabled}
            style={{ width: '65px' }}
            error={errors.entrance1}
          />
        </td>
        <td>
          <TimeInput
            onChange={(e) => this.handleExit1(e.target.value)}
            value={this.state.enabled ? this.state.exit1 : ''}
            name="exit"
            id={`exit_${day}`}
            disabled={!this.state.enabled}
            style={{ width: '65px' }}
            error={errors.exit1}
          />
        </td>
        <td>
          <TimeInput
            onChange={(e) => this.handleEntrance2(e.target.value)}
            value={this.state.enabled ? this.state.entrance2 : ''}
            name="entrance"
            id={`entrance_${day}`}
            disabled={!this.state.enabled}
            style={{ width: '65px' }}
            error={errors.entrance2}
          />
        </td>
        <td>
          <TimeInput
            onChange={(e) => this.handleExit2(e.target.value)}
            value={this.state.enabled ? this.state.exit2 : ''}
            name="exit"
            id={`exit_${day}`}
            disabled={!this.state.enabled}
            style={{ width: '65px' }}
            error={errors.exit2}
          />
        </td>
      </tr>
    );
  }
}

export default injectIntl(OpeningModalEditRow);
