import { request } from '../request';

export default {
  saveEnterpriseFestivities: (params) =>
    request({
      url: `/virtual-operator/festivities`,
      method: 'POST',
      data: params,
    }),
  saveOpening: (params) =>
    request({
      url: `/virtual-operator/opening`,
      method: 'POST',
      data: params,
    }),
  getUsers: (params) =>
    request({
      url: '/virtual-operator/users',
      method: 'GET',
      params,
    }),
  saveUser: (params) =>
    request({
      url: `/virtual-operator/users/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  getOffices: (params) =>
    request({
      url: '/virtual-operator/offices',
      method: 'GET',
      params,
    }),
  insertOffice: (params) =>
    request({
      url: `/virtual-operator/offices`,
      method: 'POST',
      data: params.data,
    }),
  editOffice: (params) =>
    request({
      url: `/virtual-operator/offices/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  getMessages: (params) =>
    request({
      url: '/virtual-operator/messages',
      method: 'GET',
      params,
    }),
  insertMessage: (params) =>
    request({
      url: `/virtual-operator/messages`,
      method: 'POST',
      data: params.data,
    }),
  editMessage: (params) =>
    request({
      url: `/virtual-operator/messages/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  deleteMessage: (id) =>
    request({
      url: `/virtual-operator/messages/${id}`,
      method: 'DELETE',
    }),
};
