import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import UserAvatar from '../../../../components/UserAvatar';
import { hasGrants } from '../../../../js/me/selectors';
import ToolboxIcon from '../../../../components/icons/ToolboxIcon';
import PbxVirtualOperatorUserModalEdit from './PbxVirtualOperatorUserModalEdit';

const messages = defineMessages({
  editUser: {
    id: 'PbxVirtualOperatorUserRow.tooltip.editUser',
    defaultMessage: 'Edit',
  },
  BLIND: {
    id: 'PbxVirtualOperatorUserRow.BLIND',
    defaultMessage: 'Blind',
  },
  SUPERVISED: {
    id: 'PbxVirtualOperatorUserRow.SUPERVISED',
    defaultMessage: 'Supervised',
  },
  CONSULTATION: {
    id: 'PbxVirtualOperatorUserRow.CONSULTATION',
    defaultMessage: 'Consultation',
  },
});

class PbxVirtualOperatorUserRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEditOpen: false,
    };
  }

  toggleEditModal = () => {
    const { modalEditOpen } = this.state;
    this.setState({
      modalEditOpen: !modalEditOpen,
    });
  };

  render() {
    const {
      user,
      canEdit,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <tr>
          <td>
            <UserAvatar
              size="md"
              alt={`${user.departmentFullname} avatar`}
              id={user.id}
              src={user.avatar}
              withPopover
            />
          </td>
          <td>{user.departmentFullname}</td>
          <td>
            {user.extensions && user.extensions.main
              ? user.extensions.main.number
              : ''}
          </td>
          <td>{user.pronunciation}</td>
          <td>{user.transfer && formatMessage(messages[user.transfer])}</td>
          {canEdit && (
            <span onClick={() => this.toggleEditModal()}>
              <ToolboxIcon name="pencil-write-2">
                {formatMessage(messages.editUser)}
              </ToolboxIcon>
            </span>
          )}
        </tr>
        {this.state.modalEditOpen && (
          <PbxVirtualOperatorUserModalEdit
            isOpen={this.state.modalEditOpen}
            onClose={this.toggleEditModal}
            user={user}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    canEdit: hasGrants(state, 'PBX_HOLIDAYS_WRITE'),
  };
}

export default injectIntl(connect(mapStateToProps)(PbxVirtualOperatorUserRow));
