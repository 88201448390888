import React from 'react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import HelpButton from '../../../../components/HelpButton';
import PbxVirtualOperator from './PbxVirtualOperator';
import PbxVirtualOperatorUsersList from './PbxVirtualOperatorUsersList';

const PbxVirtualOperatorUsersPage = (props) => (
  <PbxVirtualOperator scrolling>
    <div className="text-right">
      <HelpButton fileName="pbx-settings-virtual-operator" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <PbxVirtualOperatorUsersList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PbxVirtualOperator>
);

export default PbxVirtualOperatorUsersPage;
