import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { PhoneRulesEnums } from './PhoneRulesUtils';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_ME_PHONERULES_REQUEST,
  FETCH_ME_PHONERULES_SUCCESS,
  FETCH_ME_PHONERULES_FAILURE,
  DELETE_ME_PHONERULE_REQUEST,
  DELETE_ME_PHONERULE_SUCCESS,
  DELETE_ME_PHONERULE_FAILURE,
  TOGGLE_ME_ENABLED_PHONERULE_REQUEST,
  TOGGLE_ME_ENABLED_PHONERULE_FAILURE,
  SAVE_ME_PHONERULE_REQUEST,
  SAVE_ME_PHONERULE_SUCCESS,
  SAVE_ME_PHONERULE_FAILURE,
  FETCH_PBX_PHONERULES_REQUEST,
  FETCH_PBX_PHONERULES_SUCCESS,
  FETCH_PBX_PHONERULES_FAILURE,
  DELETE_PBX_PHONERULE_SUCCESS,
  DELETE_PBX_PHONERULE_REQUEST,
  DELETE_PBX_PHONERULE_FAILURE,
  SAVE_PBX_PHONERULE_REQUEST,
  SAVE_PBX_PHONERULE_SUCCESS,
  SAVE_PBX_PHONERULE_FAILURE,
  TOGGLE_PBX_ENABLED_PHONERULE_REQUEST,
  TOGGLE_PBX_ENABLED_PHONERULE_FAILURE,
  SET_PBX_SELECTED,
  SET_PBX_PHONERULES_FILTER,
  SAVE_USER_PHONERULE_REQUEST,
  SAVE_USER_PHONERULE_SUCCESS,
  SAVE_USER_PHONERULE_FAILURE,
} from './types';
import { PbxSettings } from '../phone/PbxSettingsUtils';
import { FETCH_PBXS_SUCCESS } from '../me/types';

const initialState = {
  me: {
    saved: true,
    loaded: true,
    errorLoading: false,
    errorDeleting: false,
    errorSaving: false,
    successDeleting: false,
    successSaving: false,
    rulesAllIds: [],
    rulesById: {},
  },
  selectedPbxId: null,
  uniquePbx: false,
  pbx: {
    saved: true,
    loaded: true,
    errorLoading: false,
    errorDeleting: false,
    errorSaving: false,
    successDeleting: false,
    successSaving: false,
    rulesAllIds: [],
    rulesById: {},
  },
  users: {
    saved: true,
    loaded: true,
    errorLoading: false,
    errorDeleting: false,
    errorSaving: false,
    successDeleting: false,
    successSaving: false,
    rulesAllIds: [],
    rulesById: {},
  },
  filter: {
    route: null,
    user: null,
  },
};

export function phoneRules(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_ME_PHONERULES_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: false,
          errorLoading: false,
        },
      };
    case FETCH_ME_PHONERULES_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: true,
          rulesById: keyBy(
            action.rules.filter((r) => !r.admin),
            (o) => o.priority
          ),
          rulesAllIds: action.rules
            .filter((r) => !r.admin)
            .map((o) => o.priority),
          errorLoading: false,
        },
      };
    case FETCH_ME_PHONERULES_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: true,
          rulesAllIds: [],
          rulesById: {},
          errorLoading: true,
        },
      };
    case DELETE_ME_PHONERULE_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: false,
          errorDeleting: false,
          successDeleting: false,
        },
      };
    case DELETE_ME_PHONERULE_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: false,
          errorDeleting: false,
          successDeleting: true,
        },
      };
    case DELETE_ME_PHONERULE_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: true,
          errorDeleting: true,
          successDeleting: false,
        },
      };
    case TOGGLE_ME_ENABLED_PHONERULE_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: true,
          rulesById: {
            ...state.me.rulesById,
            [action.rule.priority]: {
              ...state.me.rulesById[action.rule.priority],
              enabled: !state.me.rulesById[action.rule.priority].enabled,
            },
          },
          errorSaving: false,
        },
      };
    case TOGGLE_ME_ENABLED_PHONERULE_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          errorSaving: action.error,
        },
      };
    case SAVE_ME_PHONERULE_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          saved: false,
          errorSaving: false,
        },
      };
    case SAVE_ME_PHONERULE_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          saved: true,
          errorSaving: false,
        },
      };
    case SAVE_ME_PHONERULE_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          saved: true,
          errorSaving: action.error,
        },
      };
    case FETCH_PBX_PHONERULES_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: false,
          errorLoading: false,
        },
        users: {
          ...state.users,
          loaded: false,
          errorLoading: false,
        },
      };
    case FETCH_PBX_PHONERULES_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: true,
          rulesById: keyBy(
            action.rules
              .filter((r) => !r.admin)
              .filter((rule) => rule.route !== null)
              .map((rule) => {
                const isResponder =
                  rule.action === PhoneRulesEnums.SettingsActions.forward &&
                  rule.forwardCalled === PbxSettings.RESPONDER_FORWARD_NUMBER;
                return {
                  ...rule,
                  action: isResponder
                    ? PhoneRulesEnums.SettingsActions.responder
                    : rule.action,
                  forwardCalled: isResponder ? null : rule.forwardCalled,
                };
              }),
            (o) => o.priority
          ),
          rulesAllIds: action.rules
            .filter((r) => !r.admin)
            .filter((rule) => rule.route !== null)
            .map((o) => o.priority),
          errorLoading: false,
        },
        users: {
          ...state.users,
          loaded: true,
          rulesById: keyBy(
            action.rules
              .filter((r) => !r.admin)
              .filter((rule) => rule.user !== null)
              .map((rule) => {
                const isResponder =
                  rule.action === PhoneRulesEnums.SettingsActions.forward &&
                  rule.forwardCalled === PbxSettings.RESPONDER_FORWARD_NUMBER;
                return {
                  ...rule,
                  action: isResponder
                    ? PhoneRulesEnums.SettingsActions.responder
                    : rule.action,
                  forwardCalled: isResponder ? null : rule.forwardCalled,
                };
              }),
            (o) => o.priority
          ),
          rulesAllIds: action.rules
            .filter((r) => !r.admin)
            .filter((rule) => rule.user !== null)
            .map((o) => o.priority),
          errorLoading: false,
        },
      };
    case FETCH_PBX_PHONERULES_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          //pbxId: state.pbx.uniquePbx ? state.pbx.pbxId : null,
          loaded: true,
          rulesAllIds: [],
          rulesById: {},
          errorLoading: true,
        },
      };
    case DELETE_PBX_PHONERULE_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: false,
          errorDeleting: false,
          successDeleting: false,
        },
      };
    case DELETE_PBX_PHONERULE_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: false,
          errorDeleting: false,
          successDeleting: true,
        },
      };
    case DELETE_PBX_PHONERULE_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: true,
          errorDeleting: true,
          successDeleting: false,
        },
      };
    case SAVE_PBX_PHONERULE_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          saved: false,
          errorSaving: false,
        },
      };
    case SAVE_PBX_PHONERULE_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          saved: true,
          errorSaving: false,
        },
      };
    case SAVE_PBX_PHONERULE_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          saved: true,
          errorSaving: action.error,
        },
      };
    case SAVE_USER_PHONERULE_REQUEST:
      return {
        ...state,
        users: {
          ...state.pbx,
          saved: false,
          errorSaving: false,
        },
      };
    case SAVE_USER_PHONERULE_SUCCESS:
      return {
        ...state,
        users: {
          ...state.pbx,
          saved: true,
          errorSaving: false,
        },
      };
    case SAVE_USER_PHONERULE_FAILURE:
      return {
        ...state,
        users: {
          ...state.pbx,
          saved: true,
          errorSaving: action.error,
        },
      };
    case TOGGLE_PBX_ENABLED_PHONERULE_REQUEST:
      return {
        ...state,
        pbx: action.rule.user
          ? state.pbx
          : {
              ...state.pbx,
              loaded: true,
              rulesById: {
                ...state.pbx.rulesById,
                [action.rule.priority]: {
                  ...state.pbx.rulesById[action.rule.priority],
                  enabled: !state.pbx.rulesById[action.rule.priority].enabled,
                },
              },
              errorSaving: false,
            },
        users: action.rule.user
          ? {
              ...state.users,
              loaded: true,
              rulesById: {
                ...state.users.rulesById,
                [action.rule.priority]: {
                  ...state.users.rulesById[action.rule.priority],
                  enabled: !state.users.rulesById[action.rule.priority].enabled,
                },
              },
              errorSaving: false,
            }
          : state.users,
      };

    case TOGGLE_PBX_ENABLED_PHONERULE_FAILURE:
      return {
        ...state,
        pbx: action.rule.user
          ? state.pbx
          : {
              ...state.pbx,
              loaded: false,
              errorSaving: true,
            },
        users: !action.rule.user
          ? state.users
          : {
              ...state.users,
              loaded: false,
              errorSaving: true,
            },
      };
    case SET_PBX_SELECTED:
      return {
        ...state,
        selectedPbxId: action.pbxId,
      };
    case FETCH_PBXS_SUCCESS:
      return {
        ...state,
        selectedPbxId: action.pbxs.length === 1 ? action.pbxs[0].id : null,
        uniquePbx: action.pbxs.length === 1,
      };
    case LOCATION_CHANGE: {
      const isPbxRulesSection =
        action.payload.location.pathname.indexOf('/pbx/settings') === 0 ||
        action.payload.location.pathname.indexOf('/pbx/archived') === 0 ||
        action.payload.location.pathname.indexOf('/pbx/actives') === 0 ||
        action.payload.location.pathname.indexOf('/pbx/routes') === 0 ||
        action.payload.location.pathname.indexOf('/pbx/queues') === 0 ||
        action.payload.location.pathname.indexOf('/pbx/virtual-operator') === 0;
      return {
        ...state,
        me: {
          ...initialState.me,
          rulesAllIds: state.me.rulesAllIds,
          rulesById: state.me.rulesById,
        },
        selectedPbxId:
          state.uniquePbx || isPbxRulesSection ? state.selectedPbxId : null,
        pbx: {
          ...initialState.pbx,
          rulesAllIds: isPbxRulesSection ? state.pbx.rulesAllIds : [],
          rulesById: isPbxRulesSection ? state.pbx.rulesById : null,
        },
        filter: isPbxRulesSection
          ? state.filter
          : {
              route: null,
              user: null,
            },
      };
    }
    case SET_PBX_PHONERULES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      };
    default:
      return state;
  }
}
