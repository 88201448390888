import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../../components/formControls/Button';
import Select2 from '../../../../components/formControls/Select2';
import {
  getEnterpriseFestivities,
  getVirtualOperatorMessages,
  isSaveOfficeError,
  isSaveOfficeLoaded,
} from '../../../../js/virtualOperator/selectors';
import { saveVirtualOperatorOfficeRequest } from '../../../../js/virtualOperator/actions';
import ToastMessage from '../../../../components/messages/ToastMessage';
import Label from '../../../../components/formControls/Label';
import Input from '../../../../components/formControls/Input';
import { VirtualOperatorEnums } from '../../../../js/virtualOperator/VirtualOperatorUtils';
import OpeningComponent from './PbxVirtualOperatorOpeningComponent';
import FormSectionTitle from '../../../../components/FormSectionTitle';
import { getCalendarFestivitiesSettings } from '../../../../js/calendar/selectors';
import Utils from '../../../../js/lib/utils';
import FestivitiesComponent from './PbxVirtualOperatorFestivitiesComponent';

const messages = defineMessages({
  title: {
    id: 'PbxVirtualOperatorOfficeModalEdit.title',
    defaultMessage: 'Edit {route}',
  },
  name: {
    id: 'PbxVirtualOperatorOfficeModalEdit.name',
    defaultMessage: 'Name',
  },
  pronunciation: {
    id: 'PbxVirtualOperatorOfficeModalEdit.pronunciation',
    defaultMessage: 'Additional pronunciation',
  },
  transfer: {
    id: 'PbxVirtualOperatorOfficeModalEdit.transfer',
    defaultMessage: 'Transfer profile',
  },
  festivities: {
    id: 'PbxVirtualOperatorOfficeModalEdit.festivities',
    defaultMessage: 'Festivities',
  },
  opening: {
    id: 'PbxVirtualOperatorOfficeModalEdit.opening',
    defaultMessage: 'Opening table',
  },
  BLIND: {
    id: 'PbxVirtualOperatorOfficeModalEdit.BLIND',
    defaultMessage: 'Blind',
  },
  SUPERVISED: {
    id: 'PbxVirtualOperatorOfficeModalEdit.SUPERVISED',
    defaultMessage: 'Supervised',
  },
  CONSULTATION: {
    id: 'PbxVirtualOperatorOfficeModalEdit.CONSULTATION',
    defaultMessage: 'Consultation',
  },
  save: {
    id: 'PbxVirtualOperatorOfficeModalEdit.button.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'PbxVirtualOperatorOfficeModalEdit.button.cancel',
    defaultMessage: 'Cancel',
  },
  errorSaving: {
    id: 'PbxVirtualOperatorOfficeModalEdit.errorSaving',
    defaultMessage: 'Error saving',
  },
  missingWeekHours: {
    id: 'PbxVirtualOperatorOfficeModalEdit.missingWeekHours',
    defaultMessage: 'Fill the time',
  },
  missingField: {
    id: 'PbxVirtualOperatorOfficeModalEdit.missingField',
    defaultMessage: 'Fill this field',
  },
  wrongFormat: {
    id: 'PbxVirtualOperatorOfficeModalEdit.wrongFormat',
    defaultMessage: 'Wrong format.',
  },
});

class PbxVirtualOperatorOfficeModalEdit extends Component {
  constructor(props) {
    super(props);
    const festivities = {};
    const parsedFestivities = Utils.retrieveFestivities(
      [],
      props.customerFestivities.added,
      new Date().getFullYear()
    );
    for (let i = 0; i < parsedFestivities.length; i += 1) {
      festivities[parsedFestivities[i].description] = !!(
        props.office.festivities &&
        (props.office.festivities.custom.indexOf(
          parsedFestivities[i].description
        ) >= 0 ||
          props.office.festivities.standard.indexOf(
            parsedFestivities[i].description
          ) >= 0)
      );
    }
    this.state = {
      festivities,
      festivitiesMessage: {
        value: props.office.festivitiesMessage,
        label: props.office.festivitiesMessage
          ? props.createdMessages.find(
              (message) => message.id === props.office.festivitiesMessage
            ).name
          : '',
      },
      days: props.office.opening || {},
      openingMessage: {
        value: props.office.openingMessage,
        label: props.office.openingMessage
          ? props.createdMessages.find(
              (message) => message.id === props.office.openingMessage
            ).name
          : '',
      },
      name: props.office.name,
      pronunciation: props.office.pronunciation,
      transfer: props.office.transfer
        ? {
            value: props.office.transfer,
            label: props.intl.formatMessage(messages[props.office.transfer]),
          }
        : { value: null, label: '' },
      errors: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.saveLoaded &&
      !prevProps.saveLoaded &&
      !this.props.saveError
    ) {
      this.props.onClose();
    }
  };

  handleAllFestivitiesEnabled = () => {
    const festivities = {};
    Object.keys(this.state.festivities).forEach((key) => {
      festivities[key] = true;
    });
    this.setState({ festivities });
  };

  handleFestivityEnabled = (e, name) => {
    this.setState({
      festivities: {
        ...this.state.festivities,
        [name]: e.target.checked,
      },
    });
  };

  handleFestivityMessageSelection = (selected) => {
    this.setState({
      ...this.state,
      festivitiesMessage: selected,
    });
  };

  handleOpeningMessageSelection = (selected) => {
    this.setState({
      ...this.state,
      openingMessage: selected,
    });
  };

  handleName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handlePronunciation = (e) => {
    this.setState({
      pronunciation: e.target.value,
    });
  };

  handleTransfer = (selected) => {
    this.setState({
      transfer: selected,
    });
  };

  handleChangeDay = (day, data) => {
    let { days } = this.state;
    if (!data) {
      days[day] = null;
    } else {
      days = {
        ...days,
        [day]: this.state.days[day]
          ? { ...this.state.days[day], ...data }
          : data,
      };
    }
    this.setState({ days, errors: {} });
  };

  save = () => {
    const errors = {};
    Object.keys(this.state.days).forEach((day) => {
      errors[day] = {};
      if (this.state.days[day] && this.state.days[day].enabled) {
        if (!this.state.days[day].entrance1) {
          errors[day].entrance1 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (!this.state.days[day].exit1) {
          errors[day].exit1 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (!this.state.days[day].entrance2 && this.state.days[day].exit2) {
          errors[day].entrance2 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (this.state.days[day].entrance2 && !this.state.days[day].exit2) {
          errors[day].exit2 = this.props.intl.formatMessage(
            messages.missingField
          );
        }
        if (
          this.state.days[day].entrance1 &&
          this.state.days[day].entrance1.indexOf('_') >= 0
        ) {
          errors[day].entrance1 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (
          this.state.days[day].exit1 &&
          this.state.days[day].exit1.indexOf('_') >= 0
        ) {
          errors[day].exit1 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (
          this.state.days[day].entrance2 &&
          this.state.days[day].entrance2.indexOf('_') >= 0
        ) {
          errors[day].entrance2 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (
          this.state.days[day].exit2 &&
          this.state.days[day].exit2.indexOf('_') >= 0
        ) {
          errors[day].exit2 = this.props.intl.formatMessage(
            messages.wrongFormat
          );
        }
        if (this.state.days[day].entrance1 && this.state.days[day].exit1) {
          if (
            moment(this.state.days[day].entrance1, 'HH:mm').isSameOrAfter(
              moment(this.state.days[day].exit1, 'HH:mm')
            )
          ) {
            errors[day].entrance1 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
            errors[day].exit1 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
          }
        }
        if (this.state.days[day].entrance2 && this.state.days[day].exit2) {
          if (
            moment(this.state.days[day].entrance2, 'HH:mm').isSameOrAfter(
              moment(this.state.days[day].exit2, 'HH:mm')
            )
          ) {
            errors[day].entrance2 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
            errors[day].exit2 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
          }
        }
        if (
          this.state.days[day].entrance1 &&
          this.state.days[day].exit1 &&
          this.state.days[day].entrance2 &&
          this.state.days[day].exit2
        ) {
          if (
            moment(this.state.days[day].exit1, 'HH:mm').isSameOrAfter(
              moment(this.state.days[day].entrance2, 'HH:mm')
            )
          ) {
            errors[day].entrance2 = this.props.intl.formatMessage(
              messages.wrongFormat
            );
          }
        }
      }
      if (Object.keys(errors[day]).length === 0) {
        delete errors[day];
      }
      if (this.state.days[day] && !this.state.days[day].enabled) {
        delete this.state.days[day];
      }
    });
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return;
    }
    const parsedFestivities = Utils.retrieveFestivities(
      [],
      this.props.customerFestivities.added,
      new Date().getFullYear()
    );
    const standard = [];
    const custom = [];
    parsedFestivities.forEach((festivity) => {
      if (this.state.festivities[festivity.description]) {
        if (
          this.props.customerFestivities.added
            .map((item) => item.description)
            .indexOf(festivity.description) >= 0
        ) {
          custom.push(festivity.description);
        } else {
          standard.push(festivity.description);
        }
      }
    });
    this.props.saveOffice({
      id: this.props.office.id,
      data: {
        route: this.props.office.route,
        name: this.state.name,
        pronunciation: this.state.pronunciation,
        transfer: this.state.transfer ? this.state.transfer.value : null,
        opening: this.state.days,
        openingMessage: this.state.openingMessage ? this.state.openingMessage.value : null,
        festivities: { standard, custom },
        festivitiesMessage: this.state.festivitiesMessage ? this.state.festivitiesMessage.value : null,
      },
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      saveLoaded,
      saveError,
      intl: { formatMessage },
    } = this.props;
    const transferOptions = [
      {
        value: null,
        label: '',
      },
    ];
    Object.keys(VirtualOperatorEnums.TransferProfile).forEach((key) => {
      transferOptions.push({
        value: key,
        label: formatMessage(messages[key]),
      });
    });

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={onClose}>
          {formatMessage(messages.title, { route: this.props.office.route })}
        </ModalHeader>

        <ModalBody>
          {saveError && (
            <ToastMessage
              type="danger"
              text={formatMessage(messages.errorSaving)}
              closeTimeout={5000}
            />
          )}
          <div className="row">
            <div className="col-xl-12 p-3">
              <Label for="name">{formatMessage(messages.name)}</Label>
              <Input
                id="name"
                name="name"
                type="text"
                onChange={this.handleName}
                value={this.state.name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 p-3">
              <Label for="pronunciation">
                {formatMessage(messages.pronunciation)}
              </Label>
              <Input
                id="pronunciation"
                name="pronunciation"
                type="text"
                onChange={this.handlePronunciation}
                value={this.state.pronunciation}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 p-3">
              <Label for="transfer">{formatMessage(messages.transfer)}</Label>
              <Select2
                id="transfer"
                name="transfer"
                options={transferOptions}
                isClearable
                onChange={this.handleTransfer}
                value={this.state.transfer}
              />
            </div>
          </div>
          <FormSectionTitle className="mt-3 mb-3">
            {formatMessage(messages.festivities)}
          </FormSectionTitle>
          <FestivitiesComponent
            festivities={this.state.festivities}
            chosenMessage={this.state.festivitiesMessage}
            handleEnabled={this.handleFestivityEnabled}
            handleAllEnabled={this.handleAllFestivitiesEnabled}
            handleMessageSelection={this.handleFestivityMessageSelection}
          />
          <FormSectionTitle className="mt-3 mb-3">
            {formatMessage(messages.opening)}
          </FormSectionTitle>
          <OpeningComponent
            opening={this.state.days}
            chosenMessage={this.state.openingMessage}
            errors={this.state.errors}
            handleChangeDay={this.handleChangeDay}
            handleMessageSelection={this.handleOpeningMessageSelection}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.save}
            text={formatMessage(messages.save)}
            loading={!saveLoaded}
          />
          <button
            type="button"
            className="btn btn-outline-primary ml-2"
            onClick={onClose}
          >
            {formatMessage(messages.cancel)}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

PbxVirtualOperatorOfficeModalEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  office: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    customerFestivities: getCalendarFestivitiesSettings(state),
    enterpriseFestivities: getEnterpriseFestivities(state),
    saveLoaded: isSaveOfficeLoaded(state),
    saveError: isSaveOfficeError(state),
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    saveOffice: saveVirtualOperatorOfficeRequest,
  })(PbxVirtualOperatorOfficeModalEdit)
);
