import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  fetchVirtualOperatorMessagesRequest,
  fetchVirtualOperatorOfficesRequest,
} from '../../../../js/virtualOperator/actions';
import {
  getVirtualOperatorOffices,
  getVirtualOperatorOfficeTotal,
  virtualOperatorOfficesError,
  virtualOperatorOfficesLoaded,
} from '../../../../js/virtualOperator/selectors';
import { Pagination } from 'reactstrap';
import GlobalErrorPage from '../../../pages/GlobalErrorPage';
import Loading from '../../../../components/Loading';
import HelpButton from '../../../../components/HelpButton';
import Filters from '../../../../components/Filters';
import Search from '../../../../components/formControls/Search';
import PbxVirtualOperatorOfficeRow from './PbxVirtualOperatorOfficeRow';

const PAGE_SIZE = 10;

const messages = defineMessages({
  office: {
    id: 'PbxVirtualOperatorOfficesList.office',
    defaultMessage: 'Office',
  },
  number: {
    id: 'PbxVirtualOperatorOfficesList.number',
    defaultMessage: 'Number',
  },
  pronunciation: {
    id: 'PbxVirtualOperatorOfficesList.pronunciation',
    defaultMessage: 'Additional pronunciation',
  },
  transfer: {
    id: 'PbxVirtualOperatorOfficesList.transfer',
    defaultMessage: 'Transfer profile',
  },
  festivities: {
    id: 'PbxVirtualOperatorOfficesList.festivities',
    defaultMessage: 'Festivities',
  },
  opening: {
    id: 'PbxVirtualOperatorOfficesList.opening',
    defaultMessage: 'Opening table',
  },
  searchPlaceHolder: {
    id: 'PbxVirtualOperatorOfficesList.searchPlaceHolder',
    defaultMessage: 'Filter offices by name',
  },
  noRow: {
    id: 'PbxVirtualOperatorOfficesList.noRow',
    defaultMessage: 'There are no offices',
  },
});

class PbxVirtualOperatorOfficesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      name: null,
    };
  }

  componentDidMount() {
    let params = {
      name: null,
    };
    this.props.fetchMessages(params);
    params = {
      page: 0,
      pageSize: PAGE_SIZE,
      name: null,
    };
    this.props.fetch(params);
  }

  handleFilter = (name) => {
    this.setState({ name });
    const params = {
      page: 0,
      pageSize: PAGE_SIZE,
      name,
    };
    this.props.fetch(params);
  };

  handlePage = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
    const params = {
      page: pageNumber,
      pageSize: PAGE_SIZE,
      name: this.state.name,
    };
    this.props.fetch(params);
  };

  render() {
    const {
      errorFetch,
      offices,
      pages,
      loading,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <div className="text-right mb-2">
          <HelpButton fileName="pbx-virtual-operator-offices" />
        </div>
        <Filters id={this.props.id}>
          <div className="row pt-2 pb-2">
            <div className="col">
              <Search
                id="filterNameOffice"
                name="filterNameOffice"
                placeholder={formatMessage(messages.searchPlaceHolder)}
                onChange={this.handleFilter}
                text={this.state.name}
              />
            </div>
          </div>
        </Filters>
        {errorFetch ? (
          <GlobalErrorPage />
        ) : loading ? (
          <Loading />
        ) : (
          <div className="rounded border p-2 mt-2">
            <table className="table table-borderless table-hover">
              <thead
                style={{
                  color: 'var(--yn-blue-darkest)',
                }}
              >
                <tr>
                  <th>{formatMessage(messages.number)}</th>
                  <th>{formatMessage(messages.office)}</th>
                  <th>{formatMessage(messages.pronunciation)}</th>
                  <th>{formatMessage(messages.transfer)}</th>
                  <th>{formatMessage(messages.festivities)}</th>
                  <th>{formatMessage(messages.opening)}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {offices.map((office) => (
                  <PbxVirtualOperatorOfficeRow key={office} office={office} />
                ))}
              </tbody>
              {(!offices || offices.length === 0) && (
                <tfoot>
                  <tr>
                    <td colSpan="6">{formatMessage(messages.noRow)}</td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        )}
        {!loading && pages > 1 && (
          <Pagination
            pages={pages}
            currentPage={this.state.currentPage}
            handlePage={this.handlePage}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: !virtualOperatorOfficesLoaded(state),
    errorFetch: virtualOperatorOfficesError(state),
    offices: getVirtualOperatorOffices(state),
    pages: Math.ceil(getVirtualOperatorOfficeTotal(state) / PAGE_SIZE),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchVirtualOperatorOfficesRequest,
    fetchMessages: fetchVirtualOperatorMessagesRequest,
  })(PbxVirtualOperatorOfficesList)
);
