import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import OpeningModalEditRow from './PbxVirtualOperatorOpeningModalEditRow';
import Label from '../../../../components/formControls/Label';
import Select2 from '../../../../components/formControls/Select2';
import { getVirtualOperatorMessages } from '../../../../js/virtualOperator/selectors';

const messages = defineMessages({
  enabled: {
    id: 'OpeningComponent.enabled',
    defaultMessage: 'Enabled',
  },
  day: {
    id: 'OpeningComponent.day',
    defaultMessage: 'Day',
  },
  entrance: {
    id: 'OpeningComponent.entrance',
    defaultMessage: 'Entrance',
  },
  exit: {
    id: 'OpeningComponent.exit',
    defaultMessage: 'Exit',
  },
  message: {
    id: 'OpeningComponent.message',
    defaultMessage: 'Message',
  },
});

const OpeningComponent = ({
  opening,
  chosenMessage,
  createdMessages,
  errors,
  handleChangeDay,
  handleMessageSelection,
  intl: { formatMessage },
}) => {
  const messagesOptions = [
    {
      value: null,
      label: '',
    },
  ];
  createdMessages.forEach((message) => {
    messagesOptions.push({
      value: message.id,
      label: message.name,
    });
  });
  return (
    <>
      <table className="table table-borderless table-hover mt-3">
        <thead>
          <tr>
            <th>{formatMessage(messages.enabled)}</th>
            <th>{formatMessage(messages.day)}</th>
            <th>{formatMessage(messages.entrance)}</th>
            <th>{formatMessage(messages.exit)}</th>
            <th>{formatMessage(messages.entrance)}</th>
            <th>{formatMessage(messages.exit)}</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6, 7].map((day, index) => {
            return (
              <OpeningModalEditRow
                day={day}
                data={opening[day] || {}}
                key={`${day}_${index + 1}`}
                onChangeDay={(data) => handleChangeDay(day, data)}
                errors={errors[day] || {}}
              />
            );
          })}
        </tbody>
      </table>
      <div className="row">
        <div className="col-xl-12 p-3">
          <Label for="message">{formatMessage(messages.message)}</Label>
          <Select2
            id="message"
            name="message"
            options={messagesOptions}
            isClearable
            onChange={handleMessageSelection}
            value={chosenMessage}
            isDisabled={
              !Object.keys(opening).some(
                (key) => opening[key] && opening[key].enabled
              )
            }
          />
        </div>
      </div>
    </>
  );
};

OpeningComponent.propTypes = {
  opening: PropTypes.any.isRequired,
  chosenMessage: PropTypes.number,
  errors: PropTypes.any.isRequired,
  handleChangeDay: PropTypes.func.isRequired,
  handleMessageSelection: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    createdMessages: getVirtualOperatorMessages(state),
  };
}

export default injectIntl(connect(mapStateToProps)(OpeningComponent));
